<div class="rounded-lg">
    <div class="flex justify-end">
        <i (click)="openModel()"
            class="pi pi-arrow-up-right-and-arrow-down-left-from-center absolute ml-2 text-xs cursor-pointer p-1 border rounded-lg bg-white shadow-md"></i>
    </div>
    <div class="pt-8 w-full h-full">
        <div echarts [options]="data" class=" h-[20rem]  lg:h-[25rem]  xl:h-[25rem]">
        </div>
    </div>
</div>


<p-dialog header="" [modal]="true" [(visible)]="OpenGraphDialog" [style]="{ width: '50%' }">
    <div echarts [options]="data" class="w-[100%] h-[15rem] lg:w-[100%] lg:h-[25rem]"></div>
</p-dialog>