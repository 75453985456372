@if (currentScreenComponent){
<div class="flex flex-row gap-2 font-semibold h-[5%]">
    <p-button id="InsightsGoBack" label="Go Back" icon="pi pi-angle-left" (click)="goBack()"
        styleClass="text-sm"></p-button>
</div>
}
<div class="h-[95%]">
    @switch (currentScreenComponent) {
    @case (CURRENT_SCREEN_WINDOW.TRANSALTE) {
    <app-translate [contractId]="contractId"></app-translate>
    }
    @case(CURRENT_SCREEN_WINDOW.CHECKPOINTS) {
    <app-check-points [contractId]="contractId"></app-check-points>
    }
    @case (CURRENT_SCREEN_WINDOW.SUMMARY) {
    <app-summary [contractId]="contractId"></app-summary>
    }

    @case (CURRENT_SCREEN_WINDOW.KEY_HIGHLIGHTS) {
    <app-key-highlights [contractId]="contractId" />
    }
    @case (CURRENT_SCREEN_WINDOW.COMPARE) {
    <app-compare-history [documentId]="contractId" [documentType]="this.documentType"
        [navRequired]="true"></app-compare-history>
    }

    @default {
    <div class="pr-1">
        <div class=" flex flex-row justify-between items-center">
            @if(isRefreshing && refreshingUser) {
            <div class="text-xs text-gray-500 max-w-[60%] mt-1"> Currently generating insights by {{refreshingUser}}
            </div>
            }
            @else if(latestInsightDetails) {
            <div class="text-xs text-gray-500 max-w-[60%] mt-1">Insights last generated by
                {{latestInsightDetails.username}} on {{latestInsightDetails.updatedAt| date:'mediumDate'}} at
                {{latestInsightDetails.updatedAt| date:'shortTime'}} in {{ latestInsightDetails.createdAt |
                duration:
                latestInsightDetails.updatedAt }}
            </div>
            }
            <div class="ml-auto">
                <div
                    class="shadow-md shadow-orange-100 transition-all duration-300 ease-in-out active:scale-95 active:transform ">
                    <p-button (click)="generateInsights()" [disabled]="isRefreshing">
                        <div class="insights-button flex flex-row items-center gap-1" id="step5">
                            @if(isRefreshing) {
                            <i class="pi pi-spinner-dotted pi-spin mr-2"></i>
                            }
                            @else {
                            <img src="icons/star.svg" pTooltip="AI Generated" tooltipPosition="top" class=" h-5 w-5"
                                alt="Star icon">
                            }
                            Generate Insights
                        </div>
                    </p-button>
                </div>
            </div>
        </div>
    </div>
    <div class="relative w-full flex flex-col p-1.5 items-center justify-center">

        <!-- [ngClass]="{'empty-doc-restrict cursor-none' : isEmptyDoc}" -->
        <div class="w-full flex flex-col p-1.5">

            @if(isRefreshing) {
            <div class="flex flex-col">
                <div class="p-2 animate-pulse">
                    <div class="col-span-2 flex flex-col justify-between p-12 bg-gray-200 rounded-lg shadow-sm">
                    </div>
                </div>

                <div class="p-2 animate-pulse">
                    <div class="col-span-2 flex flex-col justify-between p-24 bg-gray-200 rounded-lg shadow-sm">
                    </div>
                </div>

                <div class="grid grid-cols-2 gap-4 p-2 animate-pulse">
                    <div class="flex flex-col justify-between p-12  bg-gray-200 rounded-lg shadow-sm">
                    </div>

                    <div class="flex flex-col justify-between p-12  bg-gray-200 0 rounded-lg shadow-sm">
                    </div>

                    <div class="col-span-2 flex flex-col justify-between p-12 bg-gray-200 rounded-lg shadow-sm">
                    </div>
                    <div class="col-span-2 flex flex-col justify-between p-12 bg-gray-200 rounded-lg shadow-sm">
                    </div>
                </div>
            </div>

            }
            @else{
            <div class="summary-card  mt-3 relative w-full overflow-hidden cursor-pointer" id="step6">
                <div class="rounded-lg"
                    (click)="currentWalkThroughNumber!=6 && openComponent(CURRENT_SCREEN_WINDOW.SUMMARY)">
                    <div class="p-3 pr-[10rem]"
                        [ngClass]="{'empty-doc-restrict ' : isEmptyDoc && currentWalkThroughNumber!=6}">
                        <h2 class="font-semibold text-base ">Summary</h2>
                        <p class="text-sm "> Access a comprehensive summary of your document</p>
                        <img class="h-[13rem] w-[9rem] absolute bottom-4 right-[3%] translate-y-[50%]  z-10 object-contain"
                            [ngClass]="{'translate-y-[70%]' : isEmptyDoc && currentWalkThroughNumber!=6}"
                            src="images/summary-image.png" alt="image">
                    </div>
                </div>
                @if(isEmptyDoc && !isRefreshing && currentWalkThroughNumber!=6) {
                <div class="absolute inset-0 flex items-center justify-center z-10">
                    <div
                        class="group relative p-2 rounded-full shadow-xl flex items-center justify-center text-center bg-white border opacity-1 transition-all duration-300 hover:shadow-2xl cursor-pointer">
                        <i class="pi pi-lock transition-transform duration-300 group-hover:scale-110"></i>
                        <div
                            class="absolute top-[-2rem] left-1/2 whitespace-nowrap transform -translate-x-1/2 bg-black text-white text-xs px-3 py-1 rounded opacity-0 scale-95 group-hover:opacity-100 group-hover:scale-100 transition-all duration-300">
                            No insights generated yet!
                        </div>
                    </div>
                </div>
                }
            </div>

            <div class="flex gap-2 cursor-pointer mt-3">
                <div class="relative card min-h-[12rem] border ">
                    <div class="relative  min-h-[12rem]"
                        [ngClass]="{'empty-doc-restrict' : isEmptyDoc && currentWalkThroughNumber!=7}"
                        (click)="currentWalkThroughNumber!=7 && openComponent(CURRENT_SCREEN_WINDOW.KEY_HIGHLIGHTS)"
                        id="step7">
                        <img class="h-[10rem] w-[12rem] absolute bottom-0 left-[50%] translate-x-[-50%] translate-y-[50%] z-10 object-contain"
                            src="images/insights-image.png" alt="image">
                        <div class="rounded-lg flex z-10 card-pb">
                            <div class="p-3 z-[1000]">
                                <div class="flex flex-row items-center">
                                    <h2 class="font-semibold text-base mr-2">Tags</h2>
                                </div>

                                <p class="text-sm ">Review the key information of the document</p>
                            </div>
                        </div>
                    </div>
                    @if(isEmptyDoc && !isRefreshing && currentWalkThroughNumber!=7) {
                    <div class="absolute inset-0 flex items-center justify-center z-10">
                        <div
                            class="group relative p-2 border rounded-full shadow-xl flex items-center justify-center text-center bg-white transition-all duration-300 hover:shadow-2xl cursor-pointer">
                            <i class="pi pi-lock transition-transform duration-300 group-hover:scale-110"></i>
                            <div
                                class="absolute top-[-2.5rem] left-1/2 whitespace-nowrap transform -translate-x-1/2 bg-black text-white text-xs px-3 py-1 rounded opacity-0 scale-95 group-hover:opacity-100 group-hover:scale-100 transition-all duration-300">
                                No insights generated yet!!
                            </div>
                        </div>
                    </div>
                    }
                </div>

                <div class="relative card cursor-pointer min-h-[12rem] border" id="step8">
                    <div class="relative  min-h-[12rem]"
                        [ngClass]="{'empty-doc-restrict cursor-none' : isEmptyDoc && currentWalkThroughNumber!=8}"
                        (click)="currentWalkThroughNumber!=8 && openComponent(CURRENT_SCREEN_WINDOW.CHECKPOINTS)">
                        <img class="h-[13rem] w-[9rem] absolute bottom-0 left-[50%] translate-x-[-50%] translate-y-[50%] z-10 object-contain"
                            src="images/checklists.png" alt="image">
                        <div class="rounded-lg flex z-10 card-pb">
                            <div class="p-3 z-[1000]">
                                <div class="flex flex-row items-center">
                                    <h2 class="font-semibold text-base mr-2">Checklist Items</h2>
                                </div>

                                <p class="text-sm ">Review checklists, compliance, and risk score for the
                                    document.</p>
                            </div>
                        </div>
                    </div>

                    @if(isEmptyDoc && !isRefreshing && currentWalkThroughNumber!=8) {
                    <div class="absolute inset-0 flex items-center justify-center z-10">
                        <div
                            class="group relative p-2 border rounded-full shadow-xl flex items-center justify-center text-center bg-white transition-all duration-300 hover:shadow-2xl cursor-pointer">
                            <i class="pi pi-lock transition-transform duration-300 group-hover:scale-110"></i>
                            <div
                                class="absolute top-[-2.5rem] left-1/2 transform -translate-x-1/2 bg-black text-white text-xs px-3 py-1 rounded opacity-0 scale-95 group-hover:opacity-100 group-hover:scale-100 transition-all duration-300 whitespace-nowrap">
                                No Insights generated yet!
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <p-badge *ngIf="checklistCount" class="absolute right-1" [value]="checklistCount"></p-badge>
            </div>
            <div class="summary-card border mt-3 relative w-full overflow-hidden cursor-pointer "
                (click)="currentWalkThroughNumber!=9 && openComponent(CURRENT_SCREEN_WINDOW.TRANSALTE)" id="step9">
                <div class="rounded-lg ">
                    <div class="p-3 pr-[10rem]">
                        <h2 class="font-semibold text-base ">AI Tooling</h2>
                        <p class="text-sm"> Translate to Simple Language</p>
                        <img class="h-[13rem] w-[9rem] absolute bottom-[3rem] right-[3%] translate-y-[50%] z-10 object-contain"
                            src="images/ai_tooling.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="summary-card border mt-3  relative w-full overflow-hidden cursor-pointer " id="step10">
                <div class=" rounded-lg"
                    [ngClass]="{'empty-doc-restrict cursor-none' : isEmptyDoc && currentWalkThroughNumber!=10}"
                    (click)="currentWalkThroughNumber!=10 && openComponent(CURRENT_SCREEN_WINDOW.COMPARE)">
                    <div class="p-3 pr-[10rem]">
                        <h2 class="font-semibold text-base ">Compare</h2>
                        <p class="text-sm"> Compare multiple documents using AI.</p>
                        <img class="h-[13rem] w-[9rem] absolute bottom-[3rem] right-[3%] translate-y-[51%] z-10 object-contain"
                            [ngClass]="{'translate-y-[81%]' : isEmptyDoc && currentWalkThroughNumber!=10}"
                            src="images/compare.png" alt="image">
                    </div>
                </div>
                @if(isEmptyDoc && !isRefreshing && currentWalkThroughNumber!=10) {
                <div class="absolute inset-0 flex items-center justify-center z-10">
                    <div
                        class="group relative p-2 border rounded-full shadow-xl flex items-center justify-center text-center bg-white transition-all duration-300 hover:shadow-2xl cursor-pointer">
                        <i class="pi pi-lock transition-transform duration-300 group-hover:scale-110"></i>
                        <div
                            class="absolute top-[-2.5rem] left-1/2 transform -translate-x-1/2 bg-black text-white text-xs px-3 py-1 rounded opacity-0 scale-95 group-hover:opacity-100 group-hover:scale-100 transition-all duration-300 whitespace-nowrap">
                            No Insights generated yet!
                        </div>
                    </div>
                </div>
                }
            </div>
            }
        </div>
    </div>
    }
    }
</div>