<p-button styleClass="border-2 py-1 text-sm px-2 cursor-pointer" icon="pi text-sm pi-share-alt" [rounded]="true"
    label="Share" (onClick)="openShareDialog()" />

<p-dialog [visible]="shareDialog" [appendTo]="'body'" styleClass="shadow-lg border border-gray-300 p-2 rounded-xl  "
    [modal]="true" [closeOnEscape]="true" [closable]="false" [style]="{'width':'600px' ,'height' : '500px'}"
    (onHide)="onCloseShareDialog()" [position]="'center'" [draggable]="false">
    <ng-template pTemplate="header">
        <div class="w-full flex justify-between">
            <div class="font-bold text-md truncate mr-4">Share Document '{{contract.name}}'
            </div>
            <div class="flex items-center">
                <i class="pi pi-times text-lg cursor-pointer" (click)="toggleShareDialog()"></i>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="font-semibold text-md mb-1">Invite users</div>
        <p-iconField iconPosition="left">
            <div class="flex w-full items-center space-x-2">
                <div class="flex flex-grow rounded-lg border border-gray-300">
                    <p-autoComplete [suggestions]="autoCompleteUsers" inputStyleClass="border-0 outline-none"
                        (completeMethod)="autoCompleteUsersMethod($event)" [(ngModel)]="autoCompleteValue"
                        [showEmptyMessage]="false" field="fullName" [dropdown]="false"
                        placeholder="Invite by name or email" [style]="{'width':'100%','height': '100%'}"
                        [inputStyle]="{'padding' : '0.5rem','width':'100%'}" size="100"
                        (onSelect)="addShareUser($event)">
                        <ng-template let-user pTemplate="item">
                            <div class="custom-item flex items-center p-2">
                                <div class="user-avatar">
                                    <img [src]="user | userAvatar" class="w-8 h-8 rounded-full" />
                                </div>
                                <div class="ml-3">
                                    <span class="block font-semibold">{{ user.fullName }}</span>
                                    <span class="block text-sm text-gray-500">{{ user.email }}</span>
                                </div>
                            </div>
                        </ng-template>
                    </p-autoComplete>
                </div>
                <div *ngIf="validApproverEmail()" class="flex">
                    <p-button styleClass="bg-arena-orange rounded-lg h-10 text-white px-4 ml-2"
                        (onClick)="addShareUser(null)" label="Invite" [disabled]="!validApproverEmail()"></p-button>
                </div>
            </div>
        </p-iconField>
        <div>
            <div class="font-semibold text-md my-3">Link settings</div>
            <div>
                <div class="flex justify-between">
                    <div class="flex">
                        <div class="border flex items-center p-2 rounded-lg mr-3">
                            <i class="pi pi-globe"></i>
                        </div>
                        <div>
                            <div>Anyone with the link</div>
                            <div class="text-gray-500 text-xs">Anyone with the link will have access to this document
                            </div>
                        </div>
                    </div>
                    <div>
                        <p-button (onClick)="overlayPanelAnyone.toggle($event)">
                            <div class="min-w-28">
                                <div class="px-2 py-1 flex flex-1 justify-end items-center rounded-lg">
                                    {{getAnyoneLabel(shareDetails)}}
                                    <i class="pi pi-angle-down ml-2"></i>
                                </div>
                            </div>
                        </p-button>
                        <p-overlayPanel #overlayPanelAnyone appendTo="body" styleClass="border p-0 m-0">
                            @for(option of docsShareOptions;track option){
                            <div [ngClass]="option.value==shareDetails.anyone ? 'border bg-gray-50' : ''"
                                class="mt-1 px-2 py-1 cursor-pointer hover:border rounded-lg hover:bg-gray-100"
                                (click)="updateAnyoneAccess(option.value);overlayPanelAnyone.hide()">
                                {{option.label}}
                            </div>
                            }
                        </p-overlayPanel>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <div class="flex border px-2 py-1 justify-between items-center rounded-lg">
                    <div class="underline overflow-x-scroll whitespace-nowrap no-scrollbar text-sm">{{shareUrl}}</div>
                    <i class="ml-4 pi pi-clone text-arena-orange-900 cursor-pointer"
                        (click)="copyShareUrlToClipboard()"></i>
                </div>
            </div>
        </div>
        <div class="flex flex-col flex-1 mt-4">
            <div class="font-semibold text-md">People who have access</div>
            <div class="flex flex-col mt-2 flex-1 h-full overflow-y-scroll no-scrollbar overflow-hidden">
                @for (item of sharedUsers;track item;let idx = $index) {
                <div class="flex items-center space-x-2 text-black p-2 rounded-xl">
                    <div class="relative inline-block">
                        <img [src]="item.user | userAvatar" alt="User Avatar" class="w-10 h-10 rounded-full" />
                        @if(item.type == 'EXTERNAL'){
                        <span class="absolute bottom-0 right-0 w-6 h-6 ">
                            <img [src]="'/images/external-badge.png'" alt="User Avatar" class="rounded-full"
                                [pTooltip]="'External user'" />
                        </span>
                        }
                        @else if(isUserUnverified(item)){
                        <span
                            class="absolute bottom-0 right-0 w-6 h-6 flex items-center justify-center bg-gray-500 text-white rounded-full text-xs"
                            [pTooltip]="'Email Unverified'">
                            <i class="pi pi-exclamation-circle text-white text-sm"></i>
                        </span>
                        }
                    </div>
                    <div class="flex flex-col text-sm">
                        <span>{{item.name}}</span>
                        <span class="text-gray-500">{{item.email}}</span>
                    </div>
                    <div class="flex flex-1 justify-end items-center ">
                        <div class="flex-1 flex justify-end items-center">
                            <p-button (onClick)="item.role!=SHARE_ACCESS.OWNER ? overlayPanel.toggle($event) : null">
                                <div class="min-w-28">
                                    <div class="px-2 py-1 flex flex-1 justify-end items-center rounded-lg">
                                        {{getRoleLabel(item.role)}}
                                        <i *ngIf="item.role!=SHARE_ACCESS.OWNER" class="pi pi-angle-down ml-2"></i>
                                    </div>
                                </div>
                            </p-button>
                            <p-overlayPanel #overlayPanel appendTo="body" styleClass="border p-0 m-0">
                                @for(option of docsShareOptions;track option){
                                @if(option.value!=SHARE_ACCESS.RESTRICTED){
                                <div [ngClass]="option.value==item.role ? 'border bg-gray-50' : ''"
                                    class="mt-1 px-2 py-1 cursor-pointer hover:border rounded-lg hover:bg-gray-100"
                                    (click)="updateSharedUser(option, idx)">
                                    {{option.label}}
                                </div>
                                }
                                }
                                <div class="my-1 border-t  border-gray-300"></div>
                                <div class="mt-1 px-2 py-1 cursor-pointer hover:border rounded-lg hover:bg-gray-100"
                                    (click)="removeSharedUser(idx)">
                                    Remove</div>
                            </p-overlayPanel>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    </ng-template>
</p-dialog>