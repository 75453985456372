import { Component, Input } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { FormatTextPipe } from "../../../pipes/formated-text.pipe";

@Component({
  selector: 'app-custom-table-model',
  standalone: true,
  imports: [
    DialogModule,
  ],
  templateUrl: './custom-table-model.component.html',
  styleUrl: './custom-table-model.component.scss'
})
export class CustomTableModelComponent {


  @Input() public table: string = '';
  @Input() public showOpenTable: boolean = true;

  public OpenTableDialog: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }


  openModel() {
    this.OpenTableDialog = !this.OpenTableDialog;
  }

}
