import { AfterViewInit, ComponentRef, Directive, ElementRef, OnDestroy, ViewContainerRef } from '@angular/core';
import { CustomTooltipComponent } from '../components/custom-components/custom-tooltip/custom-tooltip.component';
import { CustomTableModelComponent } from '../components/custom-components/custom-table-model/custom-table-model.component';
import { CustomGraphModelComponent } from '../components/custom-components/custom-graph-model/custom-graph-model.component';

export enum DYNAMIC_COMPONENT {
    TOOLTIP = 'TOOLTIP',
    TABLE_MODEL = 'TABLE_MODEL',
    GRAPH = 'GRAPH',
    GRAPH_MODEL = 'GRAPH_MODEL',
}

/**
 * Renders components dynamically based on configuration.
 * Usage:
 * make sure to add dynamicComponentRenderer directive to parent if html is sanitized.
 * <div 
 *      class="dynamic-component"
 *      data-component="DYNAMIC_COMPONENT.any">
 * </div>
 */
@Directive({
    selector: '[dynamicComponentRenderer]',
    standalone: true
})
export class DynamicComponentRenderer implements AfterViewInit, OnDestroy {
    private componentRefs: ComponentRef<CustomTooltipComponent>[] = [];
    private customTableModelComponentRefs: ComponentRef<CustomTableModelComponent>[] = [];
    private customGraphModelComponentRefs: ComponentRef<CustomGraphModelComponent>[] = [];
    private mutationObserver: MutationObserver | null = null
    constructor(
        private el: ElementRef,
        private viewContainerRef: ViewContainerRef,
    ) { }

    ngAfterViewInit() {
        this.renderComponents();
        this.setupMutationObserver();
    }


    private setupMutationObserver() {
        this.mutationObserver = new MutationObserver(() => {
            this.renderComponents();
        });

        // Observe changes to the host element's children and subtree
        this.mutationObserver.observe(this.el.nativeElement, {
            childList: true,
            subtree: true
        });
    }

    public renderComponents() {
        const elements = this.el.nativeElement.querySelectorAll('.dynamic-component');
        // console.log(elements)
        elements.forEach((element: HTMLElement) => {
            const component = element.getAttribute('data-component');
            switch (component) {
                case DYNAMIC_COMPONENT.TOOLTIP:
                    const tooltipContent = element.getAttribute('data-tooltip-content');
                    const tooltipNumber = element.getAttribute('data-tooltip-number');
                    if (!tooltipNumber) break;
                    const componentRef = this.viewContainerRef.createComponent(CustomTooltipComponent);
                    componentRef.instance.content = tooltipContent ?? '';
                    componentRef.instance.number = tooltipNumber;
                    this.componentRefs.push(componentRef)
                    element.replaceWith(componentRef.location.nativeElement);
                    break;
                case DYNAMIC_COMPONENT.TABLE_MODEL:
                    element.toggleAttribute('data-component')
                    const table = element.children[1].outerHTML;
                    const showOpenButton = element.hasAttribute('data-show-open-button') ? element.getAttribute('data-show-open-button') === 'true' : true;
                    if (!table) break;
                    const tableDialogComponentRef = this.viewContainerRef.createComponent(CustomTableModelComponent);
                    tableDialogComponentRef.instance.table = table;
                    tableDialogComponentRef.instance.showOpenTable = showOpenButton;
                    this.customTableModelComponentRefs.push(tableDialogComponentRef);
                    element.children[0].replaceWith(tableDialogComponentRef.location.nativeElement);
                    break
                case DYNAMIC_COMPONENT.GRAPH_MODEL:
                    const graph = element.getAttribute('data-json-content');
                    element.toggleAttribute('data-json-content')
                    if (!graph) break;
                    const graphDialogComponentRef = this.viewContainerRef.createComponent(CustomGraphModelComponent);
                    graphDialogComponentRef.instance.data = JSON.parse(graph) as any;
                    this.customGraphModelComponentRefs.push(graphDialogComponentRef);
                    element.replaceWith(graphDialogComponentRef.location.nativeElement);
                    break

                default:
            }
        });
    }

    ngOnDestroy() {
        this.componentRefs.forEach(ref => ref.destroy());
        this.customTableModelComponentRefs.forEach(ref => ref.destroy());
        this.customGraphModelComponentRefs.forEach(ref => ref.destroy());
        this.customGraphModelComponentRefs = [];
        this.customTableModelComponentRefs = [];
        this.componentRefs = [];
    }
}