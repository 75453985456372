import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { TabView, TabViewModule } from 'primeng/tabview';
import { Subscription } from 'rxjs';
import { JOB_STATUS, JOB_TYPE } from '../../../@core/models/enums';
import { IUser } from '../../../@core/models/model';
import { AuthenticationService } from '../../../@core/services/authentication.service';
import { SOCKET_EVENTS, SocketService } from '../../../@core/services/socket.service';
import { IJob, JobService } from '../../../contract-management/services/job.service';
import { IMailMeta, INotification, INotificationMeta, NotificationService } from '../../../contract-management/services/notification.service';
import { DateFormatterPipe } from '../../pipes/dateFormater.pipe';
import { FullNamePipe } from '../../pipes/fullName.pipe';
import { UserAvatarPipe } from '../../pipes/user-avatar-pipe';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ChipModule } from 'primeng/chip';
import { DurationPipe } from '../../pipes/duration.pipe';
import { MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { ToastService } from '../../../@core/services/toast.service';
import { TruncatePipe } from "../../pipes/truncate.pipe";

export enum JOB_FILTER_STATUS {
  STARTED = JOB_STATUS.STARTED,
  COMPLETED = JOB_STATUS.COMPLETED,
  FAILED = JOB_STATUS.FAILED,
  ALL = 'ALL'
}
@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [CommonModule, ButtonModule, OverlayPanelModule, UserAvatarPipe, BadgeModule, FullNamePipe, DateFormatterPipe, TabViewModule, InfiniteScrollDirective, ProgressSpinnerModule, ChipModule, DurationPipe, MenuModule, TruncatePipe],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @ViewChild(TabView) tabView!: TabView;
  @ViewChild(OverlayPanel) op!: OverlayPanel;
  notifications: INotification[] = []
  jobs: IJob[] = []
  jobFilters: { selected: JOB_FILTER_STATUS, skip: number, limit: number } = { selected: JOB_FILTER_STATUS.STARTED, skip: 0, limit: 20 }
  jobLoading: boolean = false;
  currentUser!: IUser;
  notificationsCount: number = 0;
  notificationSubscription: Subscription
  JOB_FILTER_STATUS = JOB_FILTER_STATUS
  JOB_STATUS = JOB_STATUS
  menuItems: MenuItem[] | undefined;
  constructor(private notificationService: NotificationService,
    private jobService: JobService,
    private authService: AuthenticationService,
    private router: Router,
    private socketService: SocketService,
    private toastService: ToastService
  ) {
    this.currentUser = this.authService.getUser() ?? {} as IUser;
    this.notificationSubscription = this.socketService.on<{ notification: INotification }>(SOCKET_EVENTS.NOTIFICATION).subscribe(res => {
      const notification = res[0].notification
      this.notifications.unshift(notification);
      this.notificationsCount += 1;
    })
    this.menuItems = [
      { label: 'Cancel Task', icon: 'pi pi-times-circle', style: { 'color': 'red' } },
    ];
  }

  ngOnInit() {
    this.fetchNotificationsCount();
    this.fetchNotifications();
    this.fetchJobsData(this.jobFilters);
  }

  ngOnDestroy(): void {
    if (this.notificationSubscription)
      this.notificationSubscription.unsubscribe()
  }

  open(event: any) {
    this.op.show(event);
    setTimeout(() => {
      this.tabView.updateInkBar();
    }, 0);
  }

  markAllAsRead() {
    this.notificationService.markAllAsRead().subscribe(() => {
      this.fetchNotificationsCount();
      this.fetchNotifications();
    })
  }

  fetchNotifications() {
    this.notificationService.getNotifications().subscribe((res) => {
      this.notifications = res.data.slice(0, 5)
    })
  }

  fetchNotificationsCount() {
    this.notificationService.getNotificationsCount().subscribe((res) => {
      this.notificationsCount = res.data
    })
  }

  fetchJobsData = _.debounce((jobFilters: { selected: JOB_FILTER_STATUS, skip: number, limit: number }) => {
    this.jobLoading = true
    this.jobService.getJobs(jobFilters).subscribe((res) => {
      if (res.success && res.data) {
        this.jobs.push(...(res.data ?? []))
        this.jobLoading = false
      }
      else
        this.jobLoading = false
    })
  }, 150);

  onScroll() {
    this.jobFilters.skip += 20;
    this.fetchJobsData(this.jobFilters)
  }

  chipClick(status: JOB_FILTER_STATUS) {
    if (this.jobLoading) {
      return
    }
    this.jobFilters.selected = status
    this.jobFilters.skip = 0;
    this.jobs = [];
    this.fetchJobsData(this.jobFilters)
  }

  jobText(job: IJob, title: boolean = false) {
    let statusText = '';
    switch (job.type) {
      case JOB_TYPE.REFRESH:
        statusText = 'Generating Insights';
        break;
      case JOB_TYPE.TAG_EXTRACTION:
        statusText = 'Tags Extraction';
        break;
      case JOB_TYPE.SINGLE_TAG_EXTRACTION:
        statusText = 'Single Tag Extraction';
        break;
      case JOB_TYPE.CHECKLIST:
        statusText = 'Checklist Compliance';
        break;
      case JOB_TYPE.SINGLE_CHECKLIST_ITEM:
        statusText = 'Single Checklist Compliance';
        break;
      case JOB_TYPE.SUMMARY:
        statusText = 'Summary Generation';
        break;
      case JOB_TYPE.REPO_TAG_EXTRACTION:
        statusText = 'Repository Tags Extraction';
        break;
      case JOB_TYPE.GENERATE_DOCUMENT:
        statusText = 'Document Generation';
        break;
      default:
        return '';
    }
    if (title)
      return statusText
    switch (job.status) {
      case JOB_STATUS.STARTED:
        return `${statusText} started${job.type == JOB_TYPE.GENERATE_DOCUMENT ? '' : ' for ' + job.item?.name}.`;
      case JOB_STATUS.COMPLETED:
        return `${statusText} completed successfully for ${job.item?.name}.`;
      case JOB_STATUS.FAILED:
        return `${statusText} Failed for ${job.item?.name}.`;
      case JOB_STATUS.CANCELLED:
        return `${statusText} has been cancelled for ${job.item?.name}.`;
      default:
        return '';
    }
  }

  allowCancel(job: IJob) {
    return job.status == JOB_STATUS.STARTED
  }

  onCancel(job: IJob) {
    this.jobService.cancelJob(job._id).subscribe(res => {
      if (res.success && res.data) {
        this.toastService.success('Task cancelled successfully.')
        this.chipClick(this.jobFilters.selected)
      }
    })
  }

  isNotificationMeta(meta: INotificationMeta | IMailMeta): meta is INotificationMeta {
    return (meta as INotificationMeta).message !== undefined;
  }

  isMailMeta(meta: INotificationMeta | IMailMeta): meta is IMailMeta {
    return (meta as IMailMeta).subject !== undefined;
  }

  markRead(notification: INotification) {
    this.notificationService.markRead(notification._id).subscribe(() => {
      this.fetchNotificationsCount();
      this.fetchNotifications();
    })
  }

  isNotificationClickable(notification: INotification): boolean {
    return (notification.event?.data as { contractId?: string })?.contractId !== undefined;
  }

  action(notification: INotification) {
    this.markRead(notification);
    const contractId = (notification.event.data as { contractId: string }).contractId
    if (contractId) this.navigate(contractId)
  }

  jobAction(job: IJob) {
    const contractId = job.metadata?.contractId
    if (contractId && (job.type == JOB_TYPE.GENERATE_DOCUMENT ? job.status == JOB_STATUS.COMPLETED : true))
      this.navigate(contractId)
  }

  navigate(contractId: string) {
    this.router.navigate(['/edit-document'], { queryParams: { documentId: contractId } });
  }

}
