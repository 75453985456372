@if(notificationsCount >0){
<i class="pi pi-bell text-xl cursor-pointer mr-4" pBadge [value]="notificationsCount" (click)="open($event) "></i>
}
@else {
<i class="pi pi-bell text-xl cursor-pointer mr-4" (click)="open($event)"></i>
}
<p-overlayPanel #op [style]="{'width':'28rem'}" styleClass="rounded-xl border">
    <div class="flex flex-col gap-3 w-25rem">
        <p-tabView>
            <p-tabPanel header="Notifications">
                <div class="tab-panel-items">
                    @for (notification of notifications; track $index) {
                    <div class="rounded-lg w-full p-4 border hover:bg-orange-50"
                        [ngClass]="{'bg-white': notification.meta.isRead, 'bg-orange-50': !notification.meta.isRead, 'cursor-pointer': this.isNotificationClickable(notification) }"
                        (click)="action(notification)">
                        <div class="flex flex-row gap-4 w-full justify-between items-center">
                            <div class="flex flex-row gap-4 justify-between items-center">
                                <img [src]="notification.event.triggeredBy | userAvatar" alt="Avatar"
                                    class="w-8 h-8 rounded-full" />
                                <div class="flex flex-col">
                                    <span class="font-bold">{{notification.event.triggeredBy | fullName}}</span>
                                    <div [innerHTML]="notification.meta.message " class="text-sm"></div>
                                </div>
                            </div>
                            @if(!notification.meta.isRead){
                            <div>
                                <span class="inline-block w-2 h-2 bg-orange-500 rounded-full"></span>
                            </div>
                            }
                        </div>
                        <div class="text-gray-500 text-xs text-right pt-2">{{notification?.createdAt ?? ''|
                            dateFormatter}}</div>
                    </div>
                    }

                    @if(notifications.length === 0){
                    <div class="flex flex-row justify-center items-center gap-4 p-4 rounded-lg">
                        <p>No notifications</p>
                    </div>
                    }
                </div>
            </p-tabPanel>
            <p-tabPanel header="Tasks">
                <div class="pb-4">
                    <p-chip styleClass="mr-[0.5rem] border "
                        [class.active]="jobFilters.selected.includes(JOB_FILTER_STATUS.ALL)"
                        [ngClass]="{'disabled-chip' : jobLoading , 'cursor-pointer' : !jobLoading}"
                        (click)="chipClick(JOB_FILTER_STATUS.ALL)" label="All" />
                    <p-chip styleClass="mr-[0.5rem] border "
                        [ngClass]="{'disabled-chip' : jobLoading ,  'cursor-pointer' : !jobLoading}"
                        [class.active]="jobFilters.selected.includes(JOB_FILTER_STATUS.STARTED)"
                        (click)="chipClick(JOB_FILTER_STATUS.STARTED)" label="On Going" />
                    <p-chip styleClass="mr-[0.5rem] border "
                        [ngClass]="{'disabled-chip' : jobLoading ,  'cursor-pointer' : !jobLoading}"
                        [class.active]="jobFilters.selected.includes(JOB_FILTER_STATUS.COMPLETED)"
                        (click)="chipClick(JOB_FILTER_STATUS.COMPLETED)" label="Completed" />
                    <p-chip styleClass="mr-[0.5rem] border "
                        [ngClass]="{'disabled-chip' : jobLoading,  'cursor-pointer' : !jobLoading}"
                        [class.active]="jobFilters.selected.includes(JOB_FILTER_STATUS.FAILED)"
                        (click)="chipClick(JOB_FILTER_STATUS.FAILED)" label="Failed" />
                </div>
                <div class="tab-panel-items" infiniteScroll [infiniteScrollThrottle]="500" (scrolled)="onScroll()"
                    [infiniteScrollDistance]="0" [scrollWindow]="false">
                    @for (job of jobs; track $index) {
                    <div class="job-card" [ngClass]="job.status" (click)="jobAction(job)">
                        <div class="flex flex-row gap-4 w-full justify-between items-center">
                            <div class="flex flex-row flex-1 gap-4 justify-between items-center">
                                <img [src]="job.user | userAvatar" alt="Avatar" class="w-8 h-8 rounded-full" />
                                <div class="flex flex-col flex-1">
                                    <span class="font-bold">{{jobText(job, true)}}</span>
                                    <div class="text-sm" [pTooltip]="jobText(job)">{{jobText(job)| truncate:60}}</div>
                                </div>
                                @if(allowCancel(job)){
                                <div class="ml-auto mb-auto cursor-pointer">
                                    <p-menu class="mt-5 menu-container" #menu [model]="menuItems" [popup]="true"
                                        appendTo="body" (onHide)="menu.hide()" (clickOutside)="menu.hide()">
                                        <ng-template pTemplate="item" let-item>
                                            <div class="d-flex align-items-center py-2 px-3 menu-item mt-2 cursor-pointer"
                                                (click)="onCancel(job);" [ngStyle]="item.style">
                                                <span [class]="item.icon"></span>
                                                <span class="ml-2 options">{{ item.label}}</span>
                                            </div>
                                        </ng-template>
                                    </p-menu>
                                    <div class="flex justify-center"
                                        (click)="menu.toggle($event); $event.stopPropagation()">
                                        <p-button icon="pi pi-ellipsis-v" />
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        @if(job.status!=JOB_STATUS.STARTED){
                        <div class="text-gray-500 text-xs text-right pt-2">Time Taken: {{job?.createdAt ?? ''| duration
                            :
                            job.updatedAt ?? ''}}</div>
                        }
                    </div>
                    }
                    @if(jobs.length === 0 && !jobLoading){
                    <div class="flex flex-row justify-center items-center gap-4 p-4 rounded-lg">
                        <p>No tasks present</p>
                    </div>
                    }
                </div>
                @if(jobLoading){
                <div class="flex flex-row justify-center pt-4">
                    <p-progressSpinner strokeWidth="4" [style]="{width: '30px', height: '30px'}"
                        fill="var(--surface-ground)" animationDuration=".5s" />
                </div>
                }
            </p-tabPanel>
        </p-tabView>
    </div>
    <ng-template pTemplate="footer">
        <hr class="mt-2 rounded-lg">
        <div class="flex flex-row justify-center items-center gap-4 p-3 rounded-lg">
            <p-button label="Mark all as read" styleClass="rounded-xl" severity="primary"
                [disabled]="!notificationsCount" icon="pi pi-check" (onClick)="markAllAsRead(); op.hide() " />
        </div>
    </ng-template>
</p-overlayPanel>