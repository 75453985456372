import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { IUser } from '../models/model';
import { AuthenticationService } from '../services/authentication.service';
import { ToastService } from '../services/toast.service';


@Injectable({ providedIn: 'root' })
export class LoggedInGuard implements CanActivate {
    currentUser!: IUser | null | undefined

    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private toastService: ToastService
    ) {
        this.authService.currentUser.subscribe(res => {
            this.currentUser = res
        });
    }

    async canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
        if (route.queryParams['updateEmailToken']) {
            await new Promise((resolve, _) => {
                this.authService.updateEmail(route.queryParams['updateEmailToken']).subscribe(res => {
                    if (res.success) {
                        this.toastService.success('Email updated successfully. Your window will refresh in a second.')
                        resolve(true)
                    }
                    else {
                        resolve(false)
                    }
                })
            })
        }
        if (this.currentUser?._id) {
            this.router.navigate(['/home']);
            return false
        } else {
            return true
        }
    }
}
