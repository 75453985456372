import { Component, Input } from '@angular/core';
import { SanitizeHtmlPipe } from "../../../pipes/sanitizeHtml.pipe";
import { DialogModule } from 'primeng/dialog';
import { NGX_ECHARTS_CONFIG, NgxEchartsModule } from 'ngx-echarts';

@Component({
  selector: 'app-custom-graph-model',
  standalone: true,
  imports: [
    DialogModule,
    NgxEchartsModule
  ],
  providers: [
    {
      provide: NGX_ECHARTS_CONFIG,
      useValue: {
        echarts: () => import('echarts')
      }
    }
  ],
  templateUrl: './custom-graph-model.component.html',
  styleUrl: './custom-graph-model.component.scss'
})
export class CustomGraphModelComponent {

  @Input() public data!: any;
  public OpenGraphDialog: boolean = false;

  constructor() { }

  ngOnInit() {
    this.data.legend = {
      ...(this.data?.legend ? this.data.legend : {}),
      top: "10%",
      type: "scroll",
    };

    this.data.title = {
      ...(this.data?.title ? this.data.title : {}),
      textStyle: {
        fontSize: 16,
      },
    };

    this.data.grid = {
      top: "25%",
      containLabel: true,
    };

    this.data.xAxis = {
      ...(this.data?.xAxis ? this.data.xAxis : {}),
      type: "category",
      axisLabel: {
        formatter: function (value: string) {
          return value.split(" ").join("\n");
        },
      },
    };
  }


  openModel() {
    this.OpenGraphDialog = !this.OpenGraphDialog;
  }

}
