import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { MessageService } from 'primeng/api';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { JwtInterceptor } from './@core/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './@core/interceptors/error.interceptor';
import { provideMarkdown } from 'ngx-markdown';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimations(),
    MessageService,
    provideMarkdown(),
    provideHttpClient(
      withFetch(),
      withInterceptors([
        JwtInterceptor,
        ErrorInterceptor,
      ])
    )
  ]
};
