import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContractInsightsService } from '../../services/contract-insights.service';
import { IContractInsights } from '../../models/contract-management.model';
import { DateFormatterPipe } from "../../../@shared/pipes/dateFormater.pipe";
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MarkdownModule } from 'ngx-markdown';
import { MenuItem } from 'primeng/api';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { FullNamePipe } from '../../../@shared/pipes/fullName.pipe';
import { UserAvatarPipe } from '../../../@shared/pipes/user-avatar-pipe';
import { CommonModule, DatePipe } from '@angular/common';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { FormatTextPipe } from "../../../@shared/pipes/formated-text.pipe";
import { SOCKET_EVENTS, SocketService } from '../../../@core/services/socket.service';
import { ToastService } from '../../../@core/services/toast.service';
import { DynamicComponentRenderer } from '../../../@shared/directives/dynamic-component-renderer.directive';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-summary',
  standalone: true,
  imports: [ProgressSpinnerModule, MarkdownModule, TieredMenuModule, FullNamePipe, UserAvatarPipe, DatePipe, MenuModule, ButtonModule, CommonModule, FormatTextPipe, DynamicComponentRenderer],
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.scss'
})
export class SummaryComponent implements OnInit, OnDestroy {



  showSummaryList: boolean = false;
  summaryList: IContractInsights[] = [];
  @Input() contractId: string = "";

  summaryCardMenu: MenuItem[] = [{
    label: 'Delete',
    icon: 'pi pi-trash',
    style: { 'color': 'red' },
    id: 'delete-summary'
  }];


  summaryLoading: boolean = true;
  newSummaryLoading: boolean = false;
  summary: string = '';
  updateSummary: boolean = false
  summarySubscription: any
  summaryFailedSubscription!: Subscription
  constructor(private contractInsightsService: ContractInsightsService, private socketService: SocketService, private toast: ToastService) {
    this.summarySubscription = this.socketService.on<{ summary: string }>(SOCKET_EVENTS.SUMMARY_GENERATION_COMPLETED).subscribe(result => {
      if (result?.[0]?.summary) {
        this.summary = result[0].summary
        this.newSummaryLoading = false;
        this.toggleSummaryUpdate()
      }
    });
    this.summaryFailedSubscription = this.socketService.on(SOCKET_EVENTS.SUMMARY_GENERATION_FAILED).subscribe(() => {
      this.newSummaryLoading = false;
      this.toast.error('Error generating summary.')
    });
  }

  ngOnInit(): void {
    this.fetchLatestSummary();
  }

  ngOnDestroy(): void {
    if (this.summarySubscription) {
      this.summarySubscription.unsubscribe();
    }
    if (this.summaryFailedSubscription) {
      this.summaryFailedSubscription.unsubscribe();
    }
  }

  handleMenuClick(event: Event, summaryId: string, item: MenuItem) {
    event.stopPropagation();
    console.log(event);
    switch (item.id) {
      case 'delete-summary':
        this.deleteSummary(summaryId);
        break;
    }
  }

  deleteSummary(summaryId: string): void {
    this.contractInsightsService.deleteSummary(this.contractId, summaryId).subscribe((res: any) => {
      if (res.data) {
        this.getSummaryList();
      }
    });
  }


  getSummaryList(): void {
    this.contractInsightsService.summaryList(this.contractId).subscribe((res: any) => {
      if (res.data) {
        this.summaryList = res.data;
      }
    }, () => {
      this.summaryList = [];
    });
  }

  generateSummary() {
    if (this.contractId.length > 0) {
      this.newSummaryLoading = true;
      this.contractInsightsService.generateSummary(this.contractId).subscribe({
        error: (error) => {
          this.newSummaryLoading = false;
          this.toast.error('Error generating summary.')
        }
      });
    }
  }

  fetchSummary(summaryId: string) {
    this.contractInsightsService.fetchSummary(summaryId).subscribe((res: any) => {
      if (res.data) {
        this.summary = res.data;
        this.showSummaryList = false;
      }
    });
  }

  fetchLatestSummary() {
    this.summaryLoading = true;
    this.contractInsightsService.fetchLatestSummary(this.contractId).subscribe({
      next: (res) => {
        if (res.data) {
          this.summary = res.data.summary;
          if (res.data.needsNewSummary) {
            this.generateSummary();
          }
        }
        this.summaryLoading = false;
      },
      error: (error) => {
        this.summaryLoading = false;
      }
    });
  }

  async toggleSummaryUpdate() {
    this.updateSummary = true;
    await new Promise(resolve => setTimeout(resolve, 1000));
    this.updateSummary = false;
  }

  showOrHideSummaryList() {
    if (!this.showSummaryList) {
      this.getSummaryList();
    } else {
      this.fetchLatestSummary();
    }
    this.showSummaryList = !this.showSummaryList;
  }
}
