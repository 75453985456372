import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../../../@core/services/base.service';
import { environment } from '../../../../../../environments/environment.dev';
import { IKpi } from '../../../../models/settings.model';

export enum DOCUMENT_MODEL {
    CONTRACT = 'Contract',
    REPOSITORY = 'File',
    VERSION = 'Version'
}

@Injectable({
    providedIn: 'root'
})

export class CompareService extends BaseService {

    private url = environment.apiUrl + '/compare'
    constructor(
        protected override http: HttpClient,
        protected ngZone: NgZone
    ) {
        super(http, ngZone);
    }

    getCompareResult(data: { document: string, compareDocuments: { _id: string, docModel: string, name: string }[], docModel: DOCUMENT_MODEL, comparisonId?: string }) {
        const compareDocuments = JSON.stringify(data.compareDocuments)
        return this.stream<any>(this.url + '/compare-docs', { document: data.document, compareDocuments, docModel: data.docModel, comparisonId: data.comparisonId })
    }


    getCompareKpiResult(data: { document: string, compareDocuments: { _id: string, docModel: string, name: string }[], docModel: DOCUMENT_MODEL, comparisonId?: string }) {
        const compareDocuments = JSON.stringify(data.compareDocuments)
        return this.stream<any>(this.url + '/compare-kpis', { document: data.document, compareDocuments, docModel: data.docModel, comparisonId: data.comparisonId })
    }

    downloadComparison(data: { document: string }) {
        return this.post<{ data: Uint8Array }>(this.url + '/download-comparison', { document: data.document })
    }

    getHistory(document: string) {
        return this.get<any>(this.url + '/history', { document })
    }

    getComparison(compareId: string) {
        return this.get<any>(this.url + '/comparison', { compareId })
    }

    getKpiCompareTable(data: { document: string, compareDocuments: { _id: string, docModel: string, name: string }[], kpi: IKpi['_id'] }) {
        const compareDocuments = JSON.stringify(data.compareDocuments)
        return this.get<any>(this.url + '/compare-kpi-table', { document: data.document, compareDocuments, kpi: data.kpi })
    }

    getKpiCompareGraph(data: { document: string, compareDocuments: { _id: string, docModel: string, name: string }[], kpi: IKpi['_id'] }) {
        const compareDocuments = JSON.stringify(data.compareDocuments)
        return this.get<any>(this.url + '/compare-kpi-graph', { document: data.document, compareDocuments, kpi: data.kpi })
    }

    getCustomKpi(data: { document: string, compareDocuments: { _id: string, docModel: string, name: string }[], customKpis: { comparison_aspect: string, description: string }[] }) {
        const compareDocuments = JSON.stringify(data.compareDocuments)
        const customKpis = JSON.stringify(data.customKpis)
        return this.stream<any>(this.url + '/extract-custom-kpi', { document: data.document, compareDocuments, customKpis })
    }
    updateComparisonName(compareId: string, name: string) {
        return this.put<any>(this.url + '/update-comparison', { compareId, name })
    }
    deleteComparison(compareId: string) {
        return this.delete<any>(this.url + '/delete-comparison', { compareId })
    }
}
