import { AsyncPipe, CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabView, TabViewModule } from 'primeng/tabview';
import { SplitterModule } from 'primeng/splitter';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { BehaviorSubject, Subscription, take } from 'rxjs';
import { FILE_TYPE, ONLY_OFFICE_EDITOR_TYPE, USER_TYPE } from '../../../@core/models/enums';
import { CONTRACT_USER_ROLE, IAttachment, IDocumentVersion, IShareContract, IShareUser, IUser } from '../../../@core/models/model';
import { AuthenticationService } from '../../../@core/services/authentication.service';
import { ToastService } from '../../../@core/services/toast.service';
import { ApprovalWorkflowComponent, FLOW_TYPE } from "../../../@shared/components/approval-workflow/approval-workflow.component";
import { BadgeComponent } from "../../../@shared/components/badge/badge.component";
import { CONTRACT_SIGNATORY_STATUS, CONTRACT_STATUS } from '../../../@shared/components/badge/badge.enums';
import { ContractEditorComponent } from "../../../@shared/components/contract-editor/contract-editor.component";
import { IContractComment } from '../../../@shared/components/contract-editor/model';
import { validateEmail } from '../../../@shared/components/contract-editor/plugins/utils';
import { CONTRACT_APPROVAL_STATUS, UserChipsComponent } from "../../../@shared/components/user-chips/user-chips.component";
import { ScrollResetDirective } from '../../../@shared/directives/scroll-reset.directive';
import { FullNamePipe } from '../../../@shared/pipes/fullName.pipe';
import { UserAvatarPipe } from '../../../@shared/pipes/user-avatar-pipe';
import { CommentService } from '../../../services/comment.service';
import { IContract, IContractApprovalConf, IContractSignatoryConf, SIGNATORY_TYPE } from '../../models/contract-management.model';
import { ApprovalConfService } from '../../services/approval-conf.service';
import { ContractSignatoryService } from '../../services/contract-signatory.service';
import { ContractService } from '../../services/contracts.service';
import { FingerprintService } from '../../services/fingerprintJs.service';
import { ShareContractService } from '../../services/share-contract.service';
import { UserService } from '../../services/user.service';
import { ChatComponent, ChatSource } from "../chat/chat.component";
import { CommentsComponent } from '../comments/comments.component';
import { ContractInsightsComponent } from "../contract-insights/contract-insights.component";
import { CreateContractComponent } from '../create-contract/create-contract.component';
import { DownloadDocComponent } from "./components/download-doc/download-doc.component";
import { OnlyOfficeDocEditorComponent } from '../../../@shared/components/only-office-doc-editor/only-office-doc-editor.component';
import { IConfig } from '@onlyoffice/document-editor-angular';
import { environment } from '../../../../environments/environment.dev';
import { ShareDocComponent } from "./components/share-doc/share-doc.component";
import { LinkFileComponent } from "../link-file/link-file.component";
import { JWTService } from '../../services/jwt.service';
import { TimelineModule } from 'primeng/timeline';
import { VersionService } from '../../services/version.service';
import { Driver, driver } from 'driver.js';
import { CLIENT_EVENTS, SOCKET_EVENTS, SocketService } from '../../../@core/services/socket.service';
import { PRODUCT_WALKTHROUGH_TYPE } from '../contract-list/contract-list.component'
import { ProductWalkThroughService } from '../../../@core/services/product-walk-through.service';
import { ThrottleClickDirective } from '../../../@shared/directives/throttle-click.directive';

@Component({
  selector: 'app-edit-contract',
  standalone: true,
  imports: [AvatarGroupModule, AvatarModule, ButtonModule, TabMenuModule, AsyncPipe, UserAvatarPipe, TabViewModule, DropdownModule, MenuModule, PanelModule,
    OverlayPanelModule, UserAvatarPipe, CheckboxModule, FormsModule, ConfirmPopupModule, ApprovalWorkflowComponent, FullNamePipe, ContractInsightsComponent, ShareDocComponent, MenubarModule, AutoCompleteModule, DragDropModule,
    MatDialogModule, ConfirmDialogModule, CommonModule, BadgeComponent, InputIconModule, IconFieldModule, InputTextModule, ChatComponent, DialogModule, TieredMenuModule, ScrollResetDirective, DownloadDocComponent, OnlyOfficeDocEditorComponent, LinkFileComponent,
    TimelineModule, SplitterModule, ThrottleClickDirective
  ],
  templateUrl: './edit-contract.component.html',
  styleUrl: './edit-contract.component.scss',
  providers: [ConfirmationService]
})
export class EditContractComponent implements AfterViewInit, OnInit, OnDestroy {

  htmlContent: string = ""
  contractId: string = "";
  userId: string = ""
  labelText: string = 'Send for Approval';
  actionButton: boolean = false;
  panelSizes: number[] = [70, 30];
  PANEL_SIZE_KEY = 'editor-panel-sizes';
  items: MenuItem[] | undefined;
  menuOpen = false;

  @ViewChild('contractEditor') contractEditor!: ContractEditorComponent;
  @ViewChild('onlyOfficeDocEditor') onlyOfficeDocEditor!: OnlyOfficeDocEditorComponent;
  @ViewChild('editableContent', { static: false }) editableContent!: ElementRef;
  @ViewChild('tabView') tabView!: TabView;

  contract !: IContract;
  connectedUsers$!: BehaviorSubject<IUser[]>
  CONTRACT_STATUS = CONTRACT_STATUS


  users: IUser[] = []
  approvers: (IShareUser & { profilePicture?: IUser['profilePicture'] })[] = []
  allApprovers: IShareUser[] = []
  USER_TYPE = USER_TYPE

  selectedApprovers: IUser[] = []
  signatoryEmail: string = ""
  signatoryName: string = ""
  signatoryEmailValidation: boolean = false
  approversDialog: boolean = false
  signatoryDialog: boolean = false

  approvalConf!: IContractApprovalConf
  signatoryConf!: IContractSignatoryConf
  disableAddSignatories: boolean = false
  signatories: { email: string, name: string, status: string, user: string | IUser, type: USER_TYPE }[] = []
  allSignatories: { email: string, name: string, status: string }[] = []
  signatoryType = SIGNATORY_TYPE.DOCU_SIGN
  CONTRACT_SIGNATORY_TYPE = SIGNATORY_TYPE
  CONTRACT_APPROVAL_STATUS = CONTRACT_APPROVAL_STATUS
  approver: boolean = false
  currentApprover: any
  currentUser: IUser | undefined
  createdBy: IUser | null | undefined
  flowType = FLOW_TYPE
  role: string = ''
  fetchingRole: boolean = true
  isOwner: boolean = false
  isEditor: boolean = false
  lastModified: string = ''
  loading: boolean = true
  markdownContent: string | null = null
  CONTRACT_USER_ROLE = CONTRACT_USER_ROLE
  bottomBarVisible: boolean = false
  driver!: Driver;
  walkThroughSubscription!: Subscription;

  options: MenuItem[] = []
  permissions: {
    canView: boolean,
    canEdit: boolean,
    canComment: boolean,
    restricted: boolean
  } = {
      canView: false,
      canEdit: false,
      canComment: false,
      restricted: true
    }

  dialog = inject(MatDialog)
  menuItems: MenuItem[] = [
    {
      label: 'File',
      items: [{
        label: 'New',
        icon: 'pi pi-file-plus',
        command: () => { this.createNewDocument() }
      }, {
        label: 'Open',
        icon: 'pi pi-folder-open',
        command: () => { this.openCreateDialog() }
      }, {
        label: 'Download',
        icon: 'pi pi-download',
        items: [{
          label: 'PDF',
          icon: 'pi pi-file-pdf',
        },
        {
          label: 'Word',
          icon: 'pi pi-file-word',
        }]
      }]
    }
  ]

  rightSidebarVisible: boolean = true;
  comments: IContractComment[] = []
  isAnonymousUser: boolean = false

  autoCompleteValue: string = '';
  openDownloadContract: boolean = false;
  versionLoading: boolean = false;

  showVersionsHistoryDialog: boolean = false;

  versions: IDocumentVersion[] = [];


  config: IConfig = {}
  private jwtService = new JWTService();
  i: any;
  ChatSource = ChatSource

  constructor(private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef, private router: Router, private messageService: MessageService, private contractService: ContractService, private contractSignatoryService: ContractSignatoryService,
    private fingerprintService: FingerprintService,
    private confirmationService: ConfirmationService,
    private userService: UserService,
    private authService: AuthenticationService,
    public approvalService: ApprovalConfService,
    public commentService: CommentService,
    private toast: ToastService,
    private shareService: ShareContractService,
    private versionService: VersionService,
    private socketService: SocketService,
    private productWalkThroughService: ProductWalkThroughService,
    private ngZone: NgZone
  ) {
    this.connectedUsers$ = this.contractService.connectedUsers$
    this.authService.currentUser.subscribe(res => {
      this.currentUser = res as IUser
      this.isAnonymousUser = res?.anonymous ?? false;
    });
    this.route.queryParamMap.subscribe(params => {
      this.htmlContent = params.get('htmlContent') as string ?? ""
      this.contractId = params.get('documentId') as string ?? ""
      this.userId = params.get('user') as string ?? ""
    });

    this.emitSocketEvent(CLIENT_EVENTS.JOIN_CONTRACT_ROOM)

    this.contractService.getAiGeneratedContent().pipe(take(1)).subscribe(res => {
      this.markdownContent = res
      this.contractService.setAiGeneratedContent('')
    })
    this.getUsers()
  }

  ngAfterViewInit(): void {
    if (this.htmlContent) {
      this.contractEditor.insertHTML(this.htmlContent)
    }
    this.approvalChange()

    setTimeout(() => {
      const tourType = this.currentUser?.productWalkThrough?.find(walkthrough => walkthrough.type === PRODUCT_WALKTHROUGH_TYPE.EDITOR);
      if (!tourType?.status) {
        this.initializeTour();
      }
    });
    this.walkThroughSubscription = this.productWalkThroughService.walkThroughReset$.subscribe(type => {
      if (type == PRODUCT_WALKTHROUGH_TYPE.EDITOR) {
        this.initializeTour()
      }
    })

    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.scrollToActiveTab();
        this.ngZone.run(() => { });
      }, 100);
    });

  }

  private commentsSubscription!: Subscription;
  visitorId!: string;

  isSameOrganization: boolean = false;
  signatoryUpdatedSubscription !: Subscription;
  documentUpdatedSubscription !: Subscription;
  docUpdatedAt !: string
  emptyDocument: boolean = true;

  async ngOnInit() {
    this.signatoryUpdatedSubscription = this.socketService.on(SOCKET_EVENTS.SIGNATORIES_UPDATED).subscribe(res => {
      this.fetchContractSignatories()
    })
    this.documentUpdatedSubscription = this.socketService.on<{ updatedAt: string }>(SOCKET_EVENTS.DOCUMENT_SAVED).subscribe(res => {
      if (res) this.docUpdatedAt = res[0].updatedAt
    })
    this.loadPanelSizes();
    await this.getSharedDetails();
    this.fetchContract()
    this.getLastModified()
    this.getContractApprovers()
    this.getApprovers()
    this.fetchContractSignatories()
    this.fetchAllComments()
    this.updateOptions()
    this.commentsSubscription = this.commentService.commentsObservable$.subscribe(() => {
      this.fetchAllComments();
    });
    this.getVersions()
    this.items = [
      {
        label: 'Quick tour',
        icon: 'pi pi-globe'
      },
      {
        label: 'Help',
        icon: 'pi pi-headphones'
      }
    ]
  }

  ngOnDestroy(): void {
    if (this.commentsSubscription) {
      this.commentsSubscription.unsubscribe();
    }

    if (this.signatoryUpdatedSubscription) {
      this.signatoryUpdatedSubscription.unsubscribe();
    }

    if (this.documentUpdatedSubscription) {
      this.documentUpdatedSubscription.unsubscribe();
    }
    if (this.driver) this.driver.destroy();

    this.emitSocketEvent(CLIENT_EVENTS.LEAVE_CONTRACT_ROOM)
  }

  scrollToActiveTab() {
    if (!this.tabView) return;
    const tabHeaderContainer = this.tabView.el.nativeElement.querySelector('.p-tabview-nav-container');
    if (!tabHeaderContainer) return;
    const tabHeaders = tabHeaderContainer.querySelector('.p-tabview-nav').children;

    if (tabHeaders && tabHeaders.length > 0 && this.commentService.tabActiveIndex < tabHeaders.length) {
      const activeTab = tabHeaders[this.commentService.tabActiveIndex];
      activeTab.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
    }
  }



  resetWalkthrough() {
    const insightsElement = document.getElementById("prevOfApprover")
    insightsElement?.click();

    const GoBackInsideInsightsBlocks = document.getElementById("InsightsGoBack")
    GoBackInsideInsightsBlocks?.click();

    this.initializeTour()
  }

  openJiraSupportServiceDesk() {
    window.open('https://help.doc.inncircles.ai/', '_blank');
  }

  emitSocketEvent(event: CLIENT_EVENTS) {
    const socket = this.socketService.getSocket()
    socket.emit(event, this.contractId)
  }

  loadPanelSizes() {
    const storedSizes = localStorage.getItem(this.PANEL_SIZE_KEY);
    if (storedSizes) {
      try {
        const panelSizes = JSON.parse(storedSizes);
        if (Array.isArray(panelSizes) && panelSizes.length == 2 && typeof panelSizes[0] === 'number' && typeof panelSizes[1] === 'number') {
          this.panelSizes = panelSizes;
        }
      } catch (err) {
        return;
      }
    }
  }

  onResizePanel(event: { originalEvent: Event, sizes: number[] }) {
    localStorage.setItem(this.PANEL_SIZE_KEY, JSON.stringify(event.sizes));
  }

  getDocumentFormat(attachment: IAttachment): FILE_TYPE {
    switch (attachment?.type) {
      case 'application/pdf':
        return FILE_TYPE.PDF
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return FILE_TYPE.WORD
      default:
        return FILE_TYPE.WORD
    }
  }

  getEditorTypeForFile(fileType: FILE_TYPE): ONLY_OFFICE_EDITOR_TYPE {
    switch (fileType) {
      case FILE_TYPE.WORD:
        return ONLY_OFFICE_EDITOR_TYPE.WORD
      case FILE_TYPE.PDF:
        return ONLY_OFFICE_EDITOR_TYPE.PDF
      default:
        return ONLY_OFFICE_EDITOR_TYPE.WORD
    }
  }

  waitForElement(selector: string, timeout = 5000): Promise<void> {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (document.querySelector(selector)) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
      setTimeout(() => {
        clearInterval(interval);
        reject(new Error(`Element not found: ${selector}`));
      }, timeout);
    });
  }

  fetchContractURL(url?: string, key?: string) {
    if (this.contractId.length > 0) {
      this.contractService.getContractURL(this.contractId).subscribe(async res => {
        let docFormat = this.getDocumentFormat(res.data.attachment as IAttachment)
        let editorType = this.getEditorTypeForFile(docFormat)
        url = url ? url : res.data.url
        key = key ? key : res.data.key
        if (res.success) {
          const tokenConfig: Partial<IConfig> = {
            document: {
              fileType: docFormat as string,
              key: this.contractId + "-" + key,
              title: this.contract.name,
              url: url,
              permissions: {
                chat: this.permissions.canEdit,
                comment: this.permissions.canComment,
                download: true,
                edit: this.permissions.canEdit,
                fillForms: true,
                modifyContentControl: true,
                modifyFilter: true,
                print: true,
                protect: true,
                review: this.permissions.canComment,
                reviewGroups: [],
                userInfoGroups: [],
              },
            },
            documentType: editorType,
            editorConfig: {
              user: {
                group: this.contractId,
                id: this.currentUser?.email,
                image: this.currentUser?.profilePicture?.url ? `${environment.s3Url}/${this.currentUser.profilePicture.url}?timestamp=${new Date().getTime()}` : '',
                name: this.currentUser?.firstName + ' ' + this.currentUser?.lastName
              },
              embedded: {
                toolbarDocked: "top",
              },
              customization: {
                toolbarHideFileName: true,
                hideRightMenu: true,
                compactToolbar: true,
                compactHeader: true,
                comments: true,
                forcesave: true,
                uiTheme: "theme-gray",
                logo: {
                  image: "https://arena-localhost-public.s3.ap-south-1.amazonaws.com/uploads/3.png",
                }
              },
              callbackUrl: environment.apiUrl + '/public/save-contract-document' + "?contractId=" + this.contractId + "&userId=" + this.currentUser?._id,
            },
            height: "100%",
            width: "100%",
          }
          const token = this.jwtService.signToken(tokenConfig, environment.jwtSecret);
          this.config = {
            ...tokenConfig,
            token: token
          };
        }
      });
    } else {
      this.toast.error('No document found , try uploading again !')
    }
  }

  updateOptions() {
    this.options = [
      // { label: 'Preview', icon: 'pi pi-eye', command: () => { this.action() } },
      // { label: 'See History', icon: 'pi pi-history', command: () => { this.action() } },
      { label: 'Show Versions', icon: 'pi pi-history', visible: this.isOwner || this.isEditor, command: () => { this.toggleVersionsHistoryDialog() } },
      // {
      //   label: 'Download', ic: 'pi pi-download', command: () => { this.openDownloadDailog(true) },

      // },
    ]
  }

  toggleVersionsHistoryDialog() {
    this.router.navigate(['versions'], { queryParams: { 'documentId': this.contractId } })
    // this.getVersions()
    // this.showVersionsHistoryDialog = !this.showVersionsHistoryDialog
  }

  saveVersion() {
    this.versionService.saveVersion(this.contract._id).subscribe(res => {
      if (res.success) {
        this.toast.success('Version Saved Successfully')
      }
    })
  }

  getVersions() {
    this.versionService.getVersions(this.contract._id).subscribe(res => {
      if (res.success) {
        this.versions = res.data.versions
      }
    })
  }

  showVersion(item: IDocumentVersion) {
    if (item.file && item.file.url) {
      this.versionService.getDocVersionURL(this.contract._id, item.version).subscribe(res => {
        if (res.success) {
          this.fetchContractURL(res.data.url, res.data.key)
          this.toggleVersionsHistoryDialog()
        }
      })

    }
  }

  downloadVersion(versionId: string, index: number) {
    this.versionLoading = true;
    this.versionService.downloadVersion(this.contract._id, versionId).subscribe(res => {
      if (res.success) {
        this.downloadDocument(res.data.data, `${this.contract.name} - Version${this.versions.length - index}`, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
        this.versionLoading = false;
      }
    })
  }

  deleteVersion(versionId: string) {
    this.versionService.deleteVersion(this.contract._id, versionId).subscribe(res => {
      if (res.success) {
        this.toast.success('Version Deleted Successfully')
        this.getVersions()
      }
    })
  }
  fetchContract() {
    if (this.contractId.length > 0) {
      this.contractService.fetchContract(this.contractId).subscribe((res: { data: IContract; }) => {
        this.contract = res.data
        this.docUpdatedAt = res.data.updatedAt
        this.fetchContractURL();
        this.signatoryType = res.data?.defaultSignature
        if (!res.data.defaultSignature) {
          this.fetchSignatorySetting()
        }
        this.checkContractStatus()

        this.isSameOrganization = this.currentUser?.organization === this.contract.organization
        this.isEmptyDocument()
      })
    } else {
      this.toast.error('No contract found')
    }
  }

  openDownloadDailog(status: boolean) {
    this.openDownloadContract = status
  }
  getLastModified() {
    this.loading = true;
    this.fetchContract()
    setTimeout(() => {
      this.loading = false
    }, 1000)
  }

  fetchSignatorySetting() {
    this.contractSignatoryService.getDefaultSignatory().subscribe(res => {
      if (res.success) {
        this.signatoryType = res.data?.defaultSignatory
      }
    })
  }

  sharedUsers: IShareUser[] = []
  shareDetails!: IShareContract

  async getSharedDetails() {
    return new Promise((resolve, reject) => {
      this.shareService.getShareDetails(this.contractId).subscribe(async (res) => {
        if (res.data) {
          this.shareDetails = res.data;
          this.sharedUsers = res.data.users;
          await this.getPermissionOfUser();
          this.fetchingRole = false;
          resolve(true);
        } else {
          this.fetchingRole = false;
          reject('Failed to fetch share details');
        }
      },
        err => reject(err)
      );
    });
  }

  currentRole: string = ''

  getPermissionOfUser() {
    return new Promise((resolve, reject) => {
      this.shareService.getPermissionOfUser(this.contractId).subscribe(res => {
        if (res.success) {
          this.permissions = res.data
          if (this.permissions.canEdit) {
            this.isEditor = true
            this.currentRole = CONTRACT_USER_ROLE.EDITOR
          } else if (this.permissions.canComment) {
            this.currentRole = CONTRACT_USER_ROLE.COMMENTER
          } else if (this.permissions.canView) {
            this.currentRole = CONTRACT_USER_ROLE.VIEWER
          }
          if (this.contract.createdBy == this.currentUser?._id) {
            this.isOwner = true
            this.currentRole = CONTRACT_USER_ROLE.OWNER
          }
          resolve(true)
        } else {
          reject('Failed to fetch role');
        }
      })
    })

  }

  blurSpan(event: Event) {
    const target = event.target as HTMLElement;
    target.blur();
  }

  updateContractName(event: Event) {
    event.preventDefault()
    const target = event.target as HTMLElement;
    const newName = target.textContent?.trim() || 'Untitled';
    target.innerHTML = newName
    if (this.contract && this.contract.name !== newName) {
      this.contract.name = newName;
      this.updateContract();
    }
  }

  createNewDocument() {
    const form: FormData = new FormData();
    form.append('name', 'Untitled');

    this.contractService.createContract(form).subscribe((res) => {
      if (res.success && res.data) {
        const url = this.router.createUrlTree(['edit-document'], {
          queryParams: { 'documentId': res.data._id }
        }).toString();


        window.open(url, '_blank');
      }
    });
  }

  openCreateDialog() {
    const _dialogRef = this.dialog.open(CreateContractComponent, {
      disableClose: true,
      minWidth: '40rem',
      data: { contractId: this.contractId }
    });

    _dialogRef.afterClosed().subscribe(response => {
      if (response) {
        // this.contractEditor.insertHTML(response)
        window.location.reload();
      }
    })
  }

  updateContract() {
    if (this.contractId && this.contract) {
      this.contractService.updateContract(this.contractId, this.contract).subscribe(
        res => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Contract updated successfully', life: 3000 });
        },
        error => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update contract', life: 3000 });
        }
      );
    } else {
      console.error("No contract to update");
    }
  }


  getApprovers() {

  }
  currentStepNumber = 0
  initializeTour() {
    let elementsToWait: string[] = [];
    if (this.isOwner || (this.isSameOrganization && this.permissions.canEdit)) {
      elementsToWait = ['#step1', '#step2', '#step3', '#step4'];
    } else {
      elementsToWait = ['#step5'];
    }
    Promise.all(elementsToWait.map(selector => this.waitForElement(selector)))
      .then(() => {
        this.driver = driver({
          stageRadius: 14,
          showProgress: true,
          nextBtnText: 'Next',
          allowClose: true,
          prevBtnText: 'Previous',
          onCloseClick: () => this.completeTour(),
          onDestroyed: () => this.completeTour(),
          popoverClass: 'custom-popover',
          showButtons: ['next', 'previous'],
          steps: [
            ...((this.isOwner || (this.isSameOrganization && this.permissions.canEdit)) ?
              [{ element: '#step1', popover: { title: 'Upload Document', description: 'Seamlessly upload your construction documents to the platform. Drag and drop file or select from your device to get started.', side: "left" as const, align: 'start' as const } },
              { element: '#step2', popover: { title: 'Versions', description: 'Keep a detailed record of document changes by accessing and previewing previous versions.', side: "left" as const, align: 'center' as const } },
              { element: '#step3', popover: { title: 'Share document', description: 'Securely share construction documents with internal and external stakeholders while maintaining full control over role based user permissions.', side: "left" as const, align: 'start' as const } },
              ] : []
            ),
            ...((this.isOwner || this.isEditor) ? [{ element: '#step4', popover: { title: 'Send for Approval', description: 'Submit documents for review and approval by authorized stakeholders, ensuring a structured and efficient approval process.', side: "left" as const, align: 'center' as const } },
            ] : []),
            { element: '#step5', popover: { title: 'Generate Insights', description: 'Extract key information from your document with a single click. Use this feature to analyze updates and gain meaningful insights whenever content is modified.', side: "bottom" as const, align: 'start' as const } },
            { element: '#step6', popover: { title: 'Summary', description: 'Quickly access a comprehensive overview of the document, highlighting key details and essential information.', side: "left" as const, align: 'start' as const } },
            { element: '#step7', popover: { title: 'Tags', description: 'Identify critical data extracted from the document based on customised requirements, ensuring quick accessibility of information.', side: "left" as const, align: 'start' as const } },
            { element: '#step8', popover: { title: 'Checklist Items', description: 'Review predefined checklists, compliance rate, and risk assessments to ensure document alignment project requirements.', side: "bottom" as const, align: 'center' as const } },
            { element: '#step9', popover: { title: 'AI Tooling', description: 'Leverage AI to simplify complex content, making documents easier to understand and work with.', side: "bottom" as const, align: 'start' as const } },
            { element: '#step10', popover: { title: 'Compare', description: 'Effortlessly analyze multiple documents with AI-powered comparison. Gain a consolidated summary, track key performance indicators, and engage with InnDoc AI Chat Bot for contextual insights.', side: "left" as const, align: 'start' as const } },
            { element: '#step11', popover: { title: 'Approvers', description: 'Define and manage approvers within the workflow, ensuring a structured review process for document approval or rejection.', side: "left" as const, align: 'start' as const } },
            { element: '#step12', popover: { title: 'Signatories', description: 'Configure and manage designated signatories within the workflow to ensure an organised and authorized document signing process.', side: "left" as const, align: 'start' as const } },
            { element: '#step13', popover: { title: 'Chat with AI', description: 'Engage with the InnDoc AI chatbot to ask questions, seek clarifications, and interact on document-related queries for efficient assistance.', side: "left" as const, align: 'start' as const } },
            { element: '#step14', popover: { title: 'Linked Files', description: 'Need to connect related documents? Easily link existing files for quick access or let AI assist you in generating a new document effortlessly.', side: "bottom" as const, align: 'start' as const } },
          ],
          onHighlightStarted: (element) => {
            if (!element) return;

            const stepId = element.getAttribute('id');
            if (!stepId) return;

            const stepNumber = parseInt(stepId.replace('step', ''), 10);
            this.currentStepNumber = stepNumber;
            if (stepNumber >= 11 && stepNumber <= 14) {
              (element as HTMLElement).click();
            }
            if (stepNumber == 10) {
              const insightsElement = document.getElementById("prevOfApprover");
              insightsElement?.click();
            }
          },

        });
        //});
        const element = document.getElementById((this.isOwner || (this.isSameOrganization && this.permissions.canEdit)) ? 'step1' : 'step5');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        // Start the tour after scrolling
        setTimeout(() => this.driver.drive(), 300);
      });
  }



  completeTour() {
    this.currentStepNumber = 0;
    let data = { type: PRODUCT_WALKTHROUGH_TYPE.EDITOR, status: true };
    this.userService.updateWalkThrough(data).subscribe(() => {
    });
  }

  getUsers() {
    this.userService.getUsers().subscribe(res => {
      this.users = res.data
    })
  }

  autoCompleteUsers: any[] = []

  autoCompleteUsersMethod(event: any) {
    this.autoCompleteUsers = this.users.filter((user: any) => {
      return (user.fullName.toLowerCase().includes(event.query.toLowerCase()) || user.email.toLowerCase().includes(event.query.toLowerCase()))
    })
  }


  getIconPath(): string {
    return this.labelText === 'Publish' ? '/icons/rocket.svg' : '/icons/user-check.svg';
  }


  selectApprover(user: any) {
    user.selected = !user.selected;
    if (user.selected) {
      this.selectedApprovers.push(user);
    } else {
      this.selectedApprovers = this.selectedApprovers.filter(u => u !== user);
    }
  }

  getContractApprovers() {
    this.approvalService.fetchContractApprovers(this.contractId).subscribe(res => {
      if (res.data) {
        this.approvalConf = res.data
        this.approvers = [...this.approvalConf.approvers]
        this.allApprovers = JSON.parse(JSON.stringify(this.approvalConf.approvers))
        this.currentApprover = (this.approvalConf?.approvers ?? []).find(approver => approver.email === this.currentUser?.email)
        this.bottomBarVisible = false
        if (this.currentApprover?.email == this.currentUser?.email) {
          if (this.currentApprover.status == CONTRACT_APPROVAL_STATUS.PENDING || !this.isSameOrganization) {
            this.bottomBarVisible = true
          } else if (this.isSameOrganization) {
            this.bottomBarVisible = false
          }
        }
      }
    });
  }

  saveApprovalWorkflow() {
    const approvers: IContractApprovalConf['approvers'] = this.approvers.map((approver) => {
      return {
        user: approver.user,
        email: approver.email,
        name: approver.name,
        status: CONTRACT_APPROVAL_STATUS.WAITING,
        type: approver.type
      }
    })
    this.approvalService.saveApprovalWorkflow(this.contractId, approvers).subscribe(res => {
      if (res.success) {
        this.toast.success('Approval Workflow Saved Successfully');
        this.approversDialog = false
        this.approvalChange()
      }
    });
  }


  fetchContractSignatories() {
    this.contractSignatoryService.fetchContractSignatories(this.contractId).subscribe(res => {
      if (res.success) {
        this.signatoryConf = res.data

        if (!this.signatoryConf) {
          this.signatoryConf = {} as never
          this.signatoryConf['signatories'] = []
          this.signatoryConf['status'] = CONTRACT_SIGNATORY_STATUS.NOT_YET_STARTED
        }

        switch (this.signatoryConf.status) {
          case CONTRACT_SIGNATORY_STATUS.NOT_YET_STARTED:
          case CONTRACT_SIGNATORY_STATUS.REJECTED:
            this.disableAddSignatories = false
            break;
          default:
            this.disableAddSignatories = true
        }
        this.allSignatories = this.signatoryConf.signatories
        this.signatories = this.signatoryConf.signatories.map((signatory) => {
          return {
            email: signatory.email,
            name: signatory.name,
            status: signatory.status,
            signedAttachment: signatory.signedAttachment,
            updatedAt: signatory.updatedAt,
            user: signatory.user,
            type: signatory.type
          }
        })
        this.checkContractStatus()
      }
    })
  }

  addSignatory(event: any) {
    console.log("event", event);
    const user = event.value
    const findUser = this.signatories.find((approver: any) => approver.email === user.email)
    if (findUser) {
      this.toast.warn('User already added')
      this.autoCompleteValue = ''
      return
    }
    this.signatories.push({
      email: event.value.email,
      name: event.value.fullName,
      status: CONTRACT_APPROVAL_STATUS.WAITING,
      user,
      type: USER_TYPE.INTERNAL
    })
    this.signatories = [...this.signatories]
    this.autoCompleteValue = ''
  }

  updateSignatories() {
    this.contractSignatoryService.updateSignatories(this.contractId, this.signatories).subscribe(res => {
      if (res.success) {
        this.fetchContractSignatories()
        this.toggleSignatoryDialog()
      }
    })
  }

  updateName() {
    if (validateEmail(this.signatoryEmail)) this.signatoryEmailValidation = true
    else this.signatoryEmailValidation = false
    this.signatoryName = this.signatoryEmail.substring(0, this.signatoryEmail.lastIndexOf('@') == -1 ? this.signatoryEmail.length : this.signatoryEmail.lastIndexOf('@'));
  }

  goBack() {
    this.router.navigate(['/home']);
  }

  async approvalChange() {
    this.checkContractStatus()
    this.getContractApprovers()
    this.getApprovers()
    this.getUsers()
  }

  async signatoryChange(email: string) {
    this.confirmationService.confirm({
      header: 'Are you sure?',
      message: `This action will remove the signatory 
      <span class="bg-gray-100 px-1.5 py-1 rounded-md">${email}</span>
       from the document. Do you want to proceed?`,
      accept: () => {
        this.signatories = this.signatories.filter((signatory) => signatory.email !== email)
        this.updateSignatories()
      },
      reject: () => { }
    });
  }

  downloadDocument(uint8Array: Uint8Array, fileName: string, mimeType?: string) {
    const blob: Blob = new Blob([new Uint8Array(uint8Array)], {
      type: mimeType ? mimeType : 'application/pdf'
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = mimeType ? `${fileName}.docx` : `${fileName}.pdf`;
    document.body.append(link);
    link.click();
    link.remove();
  }

  async downloadSignedDocument(event: { email: string, name: string, status: string, signedAttachment?: string }) {
    this.contractSignatoryService.downloadDocument(event.signedAttachment as string).subscribe(res => {
      if (res.success) {
        this.downloadDocument(res.data.data, `${event.name} - Signed Document - ${this.contract.name}`)
      }
    })
  }

  action() {
    if (this.contract) {
      switch (this.contract.status) {
        case CONTRACT_STATUS.READY_FOR_APPROVAL:
          if (this.approvalConf?.approvers && this.approvalConf.approvers.length > 0) {
            this.approvalService.sendForApproval(this.contractId).subscribe((res) => {
              this.toast.success('Document Sent for Approval Successfully');
              this.approvalChange()
            });
          }
          break;

        case CONTRACT_STATUS.APPROVED:
          // this.contractService.sendForSign(this.contractId).subscribe((res) => {
          //   this.checkContractStatus()
          // });
          break;

        case CONTRACT_STATUS.READY_FOR_SIGNATURE:
          if (this.signatoryConf.signatories.length > 0) {
            this.startSignatoryFlow()
            this.actionButton = true;
          }
          break;
      }
    }
  }

  checkContractStatus() {
    if (this.contractId.length > 0) {
      this.contractService.fetchContract(this.contractId).subscribe((res: { data: IContract; }) => {
        this.contract = res.data
        switch (this.contract?.status) {
          case CONTRACT_STATUS.DRAFT:
            this.labelText = 'Send for Approval';
            this.actionButton = true;
            break;

          case CONTRACT_STATUS.READY_FOR_APPROVAL:
            this.labelText = 'Send for Approval';
            this.actionButton = false;
            break;

          case CONTRACT_STATUS.SIGNATURE_IN_PROGRESS:
          case CONTRACT_STATUS.PENDING_APPROVAL:
            this.labelText = 'Publish';
            this.actionButton = true;
            break;

          case CONTRACT_STATUS.APPROVED:
            this.labelText = 'Publish';
            this.actionButton = true;
            break;
          case CONTRACT_STATUS.READY_FOR_SIGNATURE:
          case CONTRACT_STATUS.SIGNATORY_REJECTED:
            this.labelText = 'Publish';
            if ((this.signatoryConf?.signatories ?? []).length > 0) this.actionButton = false;
            else this.actionButton = true;
            break;
        }
      });
    }

  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const text = event.clipboardData?.getData('text/plain');
  }

  startSignatoryFlow() {
    this.toast.info('Document Sent for Signature');
    this.contractSignatoryService.startSignatoryFlow(this.contractId, this.signatoryType).subscribe(res => {
      if (res.success) {
        this.toast.success('Signatory Flow Started Successfully');
        this.fetchContractSignatories()
        this.actionButton = true
      } else {
        this.actionButton = false
        this.toast.warn(res.message)
      }
    })
  }

  resetSignatoryFlow() {
    this.confirmationService.confirm({
      header: 'Are you sure?',
      message: `This action will stop the current ongoing signatory flow for this document. Do you want to proceed?`,
      accept: () => {
        this.contractSignatoryService.resetSignatoryFlow(this.signatoryConf._id).subscribe(res => {
          if (res.success) {
            this.fetchContractSignatories()
          }
        })
      },
      reject: () => { }
    });
  }

  resetApprovalFlow() {
    this.confirmationService.confirm({
      header: 'Are you sure?',
      message: `This action will reset the approval flow for this document. Do you want to proceed?`,
      accept: () => {
        this.approvalService.resetApprovalWorkflow(this.contractId).subscribe(res => {
          if (res.success) {
            this.toast.success('Reset Document Successfully ');
            this.getContractApprovers();
            this.checkContractStatus()
          }
        });
      },
      reject: () => { }
    });
  }

  skipApprovalFlow() {
    this.confirmationService.confirm({
      header: 'Are you sure?',
      message: `This action will skip the approval flow for this document. Do you want to proceed?`,
      accept: () => {
        this.approvalService.skipApprovalFlow(this.contractId).subscribe(res => {
          if (res.success) {
            this.getContractApprovers();
            this.checkContractStatus()
          }
        })
      },
      reject: () => { }
    });
  }

  toggleApproveDialog() {
    this.approversDialog = !this.approversDialog
    this.getContractApprovers()
  }

  toggleSignatoryDialog() {
    this.signatoryDialog = !this.signatoryDialog
    this.fetchContractSignatories()
  }

  addInternalApprover(event: any) {
    const user = event.value
    const findUser = this.approvers.find((approver: any) => approver.email === user.email)
    if (findUser) {
      this.toast.warn('User already added')
      this.autoCompleteValue = ''
      return
    }
    this.approvers.push({
      name: user.fullName,
      user: user,
      email: user.email,
      type: USER_TYPE.INTERNAL,
      profilePicture: user.profilePicture,
    })
    this.autoCompleteValue = ''
  }

  addExternalApprover() {
    const email = this.autoCompleteValue
    const findUser = this.approvers.find((approver: any) => approver.email === email)
    if (findUser) {
      this.toast.warn('User already added')
      this.autoCompleteValue = ''
      return
    }
    this.userService.getUserDetailsByEmail(email).subscribe(res => {
      if (res.success) {
        this.autoCompleteValue = ''
        this.approvers.push({
          name: email.split('@')[0],
          email,
          user: res.data.user,
          type: res.data.type,
        })
        this.autoCompleteValue = ''
      }
    })
  }

  addExternalSignatory() {
    const email = this.autoCompleteValue
    const findUser = this.signatories.find((approver: any) => approver.email === email)
    if (findUser) {
      this.toast.warn('User already added')
      this.autoCompleteValue = ''
      return
    }
    this.userService.getUserDetailsByEmail(email).subscribe(res => {
      if (res.success) {
        this.signatories.push({
          name: email.split('@')[0],
          email,
          status: CONTRACT_APPROVAL_STATUS.WAITING,
          user: res.data.user,
          type: res.data.type
        })
        this.autoCompleteValue = ''
      }
    })
  }

  removeApprover(index: number, type: string) {
    if (type == 'approver') {
      this.approvers.splice(index, 1)
    } else if (type == 'signatory') {
      this.signatories.splice(index, 1)
    }
  }

  removeSignatory(index: number) {
    this.signatories.splice(index, 1)
  }

  editApprover: {
    [key: number]: boolean
  } = {}
  editApproverFunc(index: number) {
    this.editApprover[index] = !this.editApprover[index]
  }

  validApproverEmail() {
    return !validateEmail(this.autoCompleteValue)
  }

  isUserUnverified(item: any): boolean {
    return item && item.user && typeof item.user !== 'string' && item.user.verified === false;
  }

  draggedApproverIdx: any;

  dragStart(index: number) {
    this.draggedApproverIdx = index;
  }

  drop(dropIndex: number) {
    if (this.draggedApproverIdx !== undefined && this.draggedApproverIdx !== null && this.draggedApproverIdx !== dropIndex) {
      const draggedApprover = this.approvers[this.draggedApproverIdx];
      this.approvers.splice(this.draggedApproverIdx, 1);
      this.approvers.splice(dropIndex, 0, draggedApprover);
      this.draggedApproverIdx = null;
    }
  }

  dropSignatories(dropIndex: number) {
    if (this.draggedApproverIdx !== undefined && this.draggedApproverIdx !== null && this.draggedApproverIdx !== dropIndex) {
      const draggedApprover = this.signatories[this.draggedApproverIdx];
      this.signatories.splice(this.draggedApproverIdx, 1);
      this.signatories.splice(dropIndex, 0, draggedApprover);
      this.draggedApproverIdx = null;
    }
  }

  allowDrop(event: any) {
    event.preventDefault();
  }

  onCloseApproverDialog() {
    this.approversDialog = false
    this.approvers = []
    this.autoCompleteValue = ''
    this.editApprover = {}
  }

  onCloseSignatoryDialog() {
    this.signatoryDialog = false
    this.signatories = []
    this.autoCompleteValue = ''
    this.editApprover = {}
  }

  onApprovalChange(approvalConf: IContractApprovalConf) {
    this.approvers = approvalConf.approvers
    this.approvalConf = approvalConf
    this.checkContractStatus()
  }

  fetchAllComments() {
    this.commentService.getAllComments({ contractId: this.contractId }).subscribe(res => {
      if (res.success) {
        this.comments = res.data
      }
    })
  }

  approveDoc(action?: string) {
    this.confirmationService.confirm({
      header: 'Are you sure?',
      message: `Want to ${action} this document?`,
      accept: () => {
        if (action == 'Approve') {
          this.approvalService.approveContract(this.contractId).subscribe(res => {
            if (res.success) {
              this.toast.success('Approved document successfully');
              this.checkContractStatus()
              this.getContractApprovers()
            }
          })
        } else if (action == 'Reject') {
          this.approvalService.rejectContract(this.contractId).subscribe(res => {
            if (res.success) {
              this.toast.success('Rejected document successfully ');
              this.checkContractStatus()
              this.getContractApprovers()
            }
          });
        }
      },
      reject: () => {

      }
    });
  }

  handleClick(item: MenuItem) {
    if (item.label === 'Help') {
      this.openJiraSupportServiceDesk();
    } else if (item.label == 'Quick tour') {
      this.resetWalkthrough()
    }
    this.toggleMenu()
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }


  isEmptyDocument($event?: boolean) {
    if ($event) {
      this.emptyDocument = $event
      return
    }
    this.contractService.isEmptyDoc(this.contractId).subscribe(res => {
      this.emptyDocument = res.data.isEmpty
    })
  }

}
