<span #trigger (click)="toggleOverlay($event)"
    class="text-xs rounded-full bg-orange-500 text-white px-2 py-1 cursor-pointer">{{number}}</span>


<p-overlayPanel #op>
    <div class="flex flex-col" style="max-width: 15rem;">
        @if(sourceData.label) {
        <div class="text-sm font-bold pb-2">
            {{sourceData.label}}
        </div>
        }
        <div class="text-xs overflow-y-auto" style="max-height: 35vh;">
            <markdown [data]="isExpanded 
                ? sourceData.sourceText +  ' [Page ' + sourceData.pageNum + ']' 
                : sourceData.sourceText.length < 70 
                    ? sourceData.sourceText + ' [Page ' + sourceData.pageNum + ']' 
                    : ( sourceData.sourceText | truncate:70 : '... [Page ' + sourceData.pageNum + ']')">
            </markdown>
            @if(sourceData.sourceText.length > 70 && !isExpanded) {
            <span class="text-blue-700 cursor-pointer underline" (click)="toggleText($event)">View More</span>
            }
        </div>
    </div>
</p-overlayPanel>