import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { PasswordModule } from "primeng/password";

import { catchError } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../../@core/services/authentication.service";
import { ToastService } from "../../@core/services/toast.service";


@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
  ],
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.scss'
})
export class SignUpComponent implements OnInit {

  signupForm!: FormGroup;

  defaultUrl: string = '/home'
  returnUrl: string = this.defaultUrl;
  email: string = ''

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private toastService: ToastService,
    private authenticationService: AuthenticationService
  ) {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || this.defaultUrl;
  }

  ngOnInit() {

    this.signupForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmit() {
    if (this.signupForm.valid) {
      const credentials = this.signupForm.value;
      this.authenticationService.logout(false);
      this.authenticationService.sendOTP(credentials.email).subscribe((res) => {
        if (res.success) {
          this.toastService.success(res.message, 'Success!');
          this.router.navigate(['/verify/' + credentials.email])
        }
        else {
          this.toastService.error(res.message, 'Error !');
          this.router.navigate(['/signup'])
        }
      })

    }
  }

}