import { inject } from '@angular/core';
import { HttpRequest, HttpInterceptorFn, HttpHandlerFn, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { ToastService } from '../services/toast.service';
import { ResponseJson } from '../models/model';

export const ErrorInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const toastService = inject(ToastService)
    const authService = inject(AuthenticationService)

    return next(request).pipe(
        tap((res) => {
            if (res instanceof HttpResponse) {
                if (res.body && (res.body as ResponseJson<unknown>).success === false) {
                    toastService.error((res.body as ResponseJson<unknown>).message, 'Error')
                }
            }
        }),
        catchError(err => {
            if (err.status === 401) {
                toastService.error(err.error.message)
                authService.logout()
            } else if (err.status === 500) {
                toastService.error('Internal Server Error');
            }
            return throwError(err);
        }));
}
