import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { IUser } from './@core/models/model';
import { AuthenticationService } from './@core/services/authentication.service';
import { CLIENT_EVENTS, SOCKET_EVENTS, SocketService } from './@core/services/socket.service';
import { ToastService } from './@core/services/toast.service';
import { INotification, NOTIFICATION_EVENTS } from './contract-management/services/notification.service';
import { GeolocationService } from './services/geolocation.service';
import { LinkDocumentService } from './contract-management/services/link-document.service';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { TruncatePipe } from "./@shared/pipes/truncate.pipe";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, ToastModule, ButtonModule, TooltipModule, TruncatePipe],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'arena-ai-frontend';
  currentUser!: IUser | null | undefined;
  userUpdateSubscription!: Subscription
  notificationSubscription: Subscription

  constructor(
    private primengConfig: PrimeNGConfig,
    private authenticationService: AuthenticationService,
    private geolocationService: GeolocationService,
    private router: Router,
    private socketService: SocketService,
    private toastService: ToastService,
    private authService: AuthenticationService,
    private linkDocumentService: LinkDocumentService
  ) {

    window.addEventListener("storage", (event) => {
      if (event.key === "token") {
        window.location.reload();
      }
    });

    this.authService.currentUser.subscribe(res => {
      this.currentUser = res
      this.emitSocketEvent(CLIENT_EVENTS.JOIN_ORG_ROOM)
      this.emitSocketEvent(CLIENT_EVENTS.JOIN_USER_ROOM)
    })

    this.userUpdateSubscription = this.socketService.on<{ token: string }>(SOCKET_EVENTS.USER_UPDATED).subscribe(res => {
      this.authService.setUserData(res[0])
      setTimeout(() => {
        window.location.reload();
      }, 1000)
    })

    this.notificationSubscription = this.socketService.on<{ notification: INotification }>(SOCKET_EVENTS.NOTIFICATION).subscribe(res => {
      switch (res[0].notification.subModule) {
        case NOTIFICATION_EVENTS.DOCUMENT.GENERATED:
          const notification = res[0].notification.event.data as { contractName: string, contractId: string };
          this.linkDocumentService.reloadLinkedFiles.next(null);
          this.toastService.success(
            `${notification.contractName} has been generated successfully`,
            'Click here to redirect to document!',
            () => {
              this.router.navigate(['/edit-document'], { queryParams: { documentId: notification.contractId } }).then(() => location.reload());
            }
          );
          break;
        default:
      }
    })
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.primengConfig.zIndex = {
      modal: 1200,    // dialog, sidebar
      overlay: 1200,  // dropdown, overlaypanel
      menu: 1200,     // overlay menus
      tooltip: 1200   // tooltip
    };
    this.geolocationService.fetchCountryCodes()
    window.addEventListener('message', this.receiveMessage.bind(this), false);
  }

  ngOnDestroy(): void {
    if (this.userUpdateSubscription) {
      this.userUpdateSubscription.unsubscribe();
    }
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
    this.emitSocketEvent(CLIENT_EVENTS.LEAVE_ORG_ROOM)
    this.emitSocketEvent(CLIENT_EVENTS.LEAVE_USER_ROOM)

  }

  emitSocketEvent(event: CLIENT_EVENTS) {
    const socket = this.socketService.getSocket()
    const payload = (event == CLIENT_EVENTS.JOIN_ORG_ROOM ? (this.currentUser?.organization as string ?? '') : (this.currentUser?._id as string ?? ''))
    socket.emit(event, payload)
  }

  receiveMessage(event: MessageEvent) {
    // Verify the origin of the data for security
    if (event.origin !== 'http://localhost:4200') return;

    if (event.data.type === 'ARENA_CONTRACT_ANALYSER') {
      const data = event.data.data;
      const user = this.authenticationService.setUserData(data);
      if (user) {
        this.router.navigate(['/home'])
      } else {
        this.router.navigate(['/'])
      }
    }
  }
}
