<p-toast position="top-right">
    <ng-template let-message pTemplate="message">
        <div class="toast-content" (click)="message.onClick && message.onClick()">
            <div class="toast-header">
                <div class="header-left">
                    <i class="pi" [ngClass]="'pi-' + (
                        message.severity === 'success' ? 'check-circle' :
                        message.severity === 'info' ? 'info-circle' :
                        message.severity === 'warn' ? 'exclamation-triangle' :
                        'times-circle'
                    )"></i>
                    <span class="text-justify">{{message.summary}}</span>
                </div>
            </div>
            <div class="toast-body">
                <div class="icon-spacer"></div>
                <div class="body-content text-xs break-words" [tooltipDisabled]="message.detail.length <= 80"
                    tooltipPosition="top" [pTooltip]="message.detail" [showDelay]="1000">
                    {{message.detail
                    |truncate:80}}</div>

            </div>
        </div>
    </ng-template>
</p-toast>
<router-outlet></router-outlet>