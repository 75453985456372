import { Component } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router, RouterModule } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';
import { filter } from 'rxjs/operators';
import { IUser } from '../../../@core/models/model';
import { AuthenticationService } from '../../../@core/services/authentication.service';
import { ProductWalkThroughService } from '../../../@core/services/product-walk-through.service';
import { NotificationsComponent } from "../../../@shared/components/notifications/notifications.component";
import { UserAvatarPipe } from '../../../@shared/pipes/user-avatar-pipe';
import { PRODUCT_WALKTHROUGH_TYPE } from '../../../contract-management/components/contract-list/contract-list.component';

@Component({
  selector: 'app-top-bar',
  standalone: true,
  imports: [AvatarModule, SidebarModule, ButtonModule, BadgeModule, RouterModule, UserAvatarPipe, MenuModule, NotificationsComponent],
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.scss'
})
export class TopBarComponent {
  items: MenuItem[] | undefined;
  sidebarVisible: boolean = false;
  currentUser!: IUser | null | undefined;
  presentWalkthroughScreen: PRODUCT_WALKTHROUGH_TYPE | null = null
  constructor(private authService: AuthenticationService, private router: Router, private productWalkThroughService: ProductWalkThroughService) {
    this.authService.currentUser.subscribe(res => this.currentUser = res)
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.updatePresentWalkthrough(event.urlAfterRedirects);
        }
      });
  }

  ngOnInit(): void {
    this.items = [
      {
        items: [
          {
            label: 'Profile',
            icon: 'pi pi-user',
            route: '/home/profile'
          },
          {
            label: 'Help',
            icon: 'pi pi-headphones',
            route: '',
          },
          {
            label: 'Log Out',
            icon: 'pi pi-sign-in',
            route: '',
          }
        ]
      }
    ];
  }

  handleClick(item: { label: string, icon: string, route: string, extraRouteArgs: NavigationExtras }) {
    if (item.label === 'Log Out') {
      this.authService.logout();
    } else if (item.label == 'Profile') {
      this.router.navigate([item.route]);
    } else if (item.label == 'Help') {
      this.openHelp();
    }
  }

  updatePresentWalkthrough(url: string) {
    if (url.includes('settings'))
      this.presentWalkthroughScreen = PRODUCT_WALKTHROUGH_TYPE.SETTINGS
    else if (url == '/home')
      this.presentWalkthroughScreen = PRODUCT_WALKTHROUGH_TYPE.HOME
    else if (url.includes('compare'))
      this.presentWalkthroughScreen = PRODUCT_WALKTHROUGH_TYPE.COMPARE
    else
      this.presentWalkthroughScreen = null
  }

  resetWalkthrough() {
    if (this.presentWalkthroughScreen)
      this.productWalkThroughService.resetWalkThrough(this.presentWalkthroughScreen);
  }

  openHelp() {
    window.open('https://help.doc.inncircles.ai/', '_blank');
  }
}
