import { Component, ElementRef, Input, NgZone, ViewChild } from '@angular/core';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { TruncatePipe } from '../../../pipes/truncate.pipe';
import { DialogModule } from 'primeng/dialog';
import { MarkdownModule } from 'ngx-markdown';

@Component({
  selector: 'app-custom-tooltip',
  standalone: true,
  imports: [OverlayPanelModule, TruncatePipe, DialogModule, MarkdownModule],
  templateUrl: './custom-tooltip.component.html',
  styleUrl: './custom-tooltip.component.scss'
})
export class CustomTooltipComponent {
  @ViewChild('op') overlayPanel!: OverlayPanel;
  @ViewChild('trigger') triggerElement!: ElementRef;


  @Input() public number: string = '';
  @Input() public content: string = ''
  sourceData!: {
    label: string,
    sourceText: string,
    pageNum: string
  }
  isExpanded = false;

  constructor(private ngZone: NgZone) { }

  ngOnInit(): void {
    if (this.content) {
      this.sourceData = JSON.parse(this.content)
      // if (this.sourceData?.pageNum) {
      //   this.sourceData.sourceText += ` [Page ${this.sourceData.pageNum}]`
      // }
    }
  }

  toggleOverlay(event: MouseEvent) {
    this.overlayPanel.toggle(event)
    this.isExpanded = false
  }

  toggleText(event: Event) {
    event.stopPropagation()
    this.isExpanded = true

    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.ngZone.run(() => {
          const position = this.calculateOptimalPosition();
          this.updatePanelPosition(position);
        });
      }, 50);
    });
  }

  calculateOptimalPosition() {
    if (!this.overlayPanel || !this.triggerElement) return null;

    const trigger = this.triggerElement.nativeElement.getBoundingClientRect();
    const panel = this.overlayPanel!.container!.getBoundingClientRect();
    const viewport = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    let position = {
      top: trigger.bottom + window.scrollY,
      left: trigger.left + window.scrollX
    };

    if (position.top + panel.height > viewport.height + window.scrollY) {
      position.top = trigger.top - panel.height + window.scrollY;
    }

    if (position.left + panel.width > viewport.width + window.scrollX) {
      position.left = trigger.right - panel.width + window.scrollX;
    }

    return position;
  }

  updatePanelPosition(position: any) {
    if (!position || !this.overlayPanel.container) return;

    this.overlayPanel.container.style.position = 'absolute';
    this.overlayPanel.container.style.top = `${position.top}px`;
    this.overlayPanel.container.style.left = `${position.left}px`;
  }


}