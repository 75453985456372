import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { DOCUMENT_MODEL } from "../../../../../../@shared/components/contract-editor/contract-editor.service";
import { IUser } from "../../../../../../@core/models/model";
import { AuthenticationService } from "../../../../../../@core/services/authentication.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MarkdownModule } from "ngx-markdown";
import { ContractService } from "../../../../../services/contracts.service";
import { IContract } from "../../../../../models/contract-management.model";
import { CommonModule } from "@angular/common";
import { TruncatePipe } from "../../../../../../@shared/pipes/truncate.pipe";
import { CompareSelectionComponent } from "../compare-selection/compare-selection.component";
import { MatDialog } from "@angular/material/dialog";
import { SkeletonModule } from "primeng/skeleton";
import { ChatComponent, ChatSource } from "../../../../chat/chat.component";
import { CompareService } from "../compare.service";
import { TopBarComponent } from "../../../../../../app-layout/components/top-bar/top-bar.component";
import { ButtonModule } from "primeng/button";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SplitterModule } from "primeng/splitter";
import { IFile } from "../../../../settings/repository/repository.types";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DialogModule } from 'primeng/dialog';
import { SidebarModule } from "primeng/sidebar";
import { CompareHistoryComponent } from "../compare-history/compare-history.component";
import { ScrollResetDirective } from "../../../../../../@shared/directives/scroll-reset.directive";
import { ToastService } from "../../../../../../@core/services/toast.service";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { FormatTextPipe } from "../../../../../../@shared/pipes/formated-text.pipe";
import { TooltipModule } from "primeng/tooltip";
import { Driver, driver } from "driver.js";
import { PRODUCT_WALKTHROUGH_TYPE } from '../../../../contract-list/contract-list.component';
import { UserService } from '../../../../../services/user.service';
import { Subscription } from "rxjs";
import { ProductWalkThroughService } from "../../../../../../@core/services/product-walk-through.service";
import _ from "lodash";

@Component({
    selector: 'app-compare-view',
    standalone: true,
    imports: [
        MarkdownModule,
        TruncatePipe,
        CommonModule,
        SkeletonModule,
        ChatComponent,
        TopBarComponent,
        ButtonModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        SplitterModule,
        MatSlideToggleModule,
        DialogModule,
        SidebarModule,
        CompareHistoryComponent,
        ScrollResetDirective,
        ProgressSpinnerModule,
        FormatTextPipe,
        TooltipModule
    ],
    templateUrl: './compare-view.component.html',
    styleUrl: './compare-view.component.scss',
    providers: []
})
export class CompareViewComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('editableContent') editableContent!: ElementRef;

    loading = true;
    compareResult: string = '';
    kpiResult!: { _id: string, aspect: string, comparison: string, generateTable: boolean, generateGraph: boolean }[];
    panelSizes: number[] = [75, 25];
    currentUser!: IUser | null | undefined
    documentId!: string
    comparedDocumentId!: string
    documentType!: string
    document!: IContract & { summary: string }
    comparedDocument!: IContract & { summary: string }
    compareDocumentsIds: { _id: string, docModel: string, name: string }[] = [];
    compareDocuments: (IContract | IFile & { summary: string })[] = [];
    isExpanded = false;
    compareType!: DOCUMENT_MODEL;
    COMPARISON_LIMIT = 9;
    toggleList: boolean[] = []
    comparedList: boolean[] = []
    enableCompare: boolean = false;
    toggleKpiDailog: boolean = false;
    toogleComparison: boolean = false;
    selectedKpi!: { aspect: string, comparison: string };
    isKpiLoading: boolean = false;
    isSummaryLoading: boolean = false;
    changeDocLoading: boolean = false;
    downloadLoading: boolean = false;
    showHistory: boolean = false;
    compareId: IContract['_id'] = '';
    comparisonExists: boolean = false;
    driver!: Driver;
    compareName: string = 'Compare Docs';
    kpiDialog: {
        mode: 'Add' | 'Edit',
        visible: boolean,
        index: number
    } = { mode: 'Add', visible: false, index: 0 }

    kpiName: string = ''
    kpiDescription: string = ''
    ChatSource = ChatSource
    editName: boolean = false;

    kpiCompareTableDialog: {
        visible: boolean,
        loading: boolean
        table: string
    } = { visible: false, loading: false, table: '' }

    kpiCompareGraphDialog: {
        visible: boolean,
        loading: boolean
        graph: string,
        graphType: string
    } = { visible: false, loading: false, graph: '', graphType: '' }

    graphCache: { [kpiId: string]: { graph: string, graphType: string } } = {};
    suggestions: string[] = [
        'At what percentages the Daily/Weekly/Monthly rates changed ',
        'What is the highest rate increase in terms of monetary value and percentage for a specific item ?',
        'List the items that faced a rate decrease',
        'Which items had no change in their rates ?',
        'Which is the best quote among these quotes ?',
        'Compare the rates in the tables and give a synopsis'
    ]
    walkThroughSubscription!: Subscription

    constructor(
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthenticationService,
        private compareService: CompareService,
        private contractsService: ContractService,
        private toast: ToastService,
        private cdr: ChangeDetectorRef,
        private userService: UserService,
        private productWalkThroughService: ProductWalkThroughService
    ) {
        this.authService.currentUser.subscribe(res => {
            this.currentUser = res
        });
        this.route.queryParamMap.subscribe(params => {

            this.compareId = params.get('compareId') as string;
            if (this.compareId) {
                this.comparisonExists = true;
            }
            else {
                this.documentId = params.get('documentId') as string;
                this.comparedDocumentId = params.get('comparedDocumentId') as string;
                let compareDocName = params.get('comparedDocumentName') as string;
                this.compareType = params.get('compareType') as DOCUMENT_MODEL;
                this.documentType = params.get('documentType') as string;
                let data: {
                    contract: IContract,
                    docModel: DOCUMENT_MODEL
                }[] = JSON.parse(params.get('data') as string) ?? []
                for (let i = 0; i < data?.length; i++) {
                    this.compareDocumentsIds.push({
                        _id: data[i].contract._id,
                        docModel: data[i].docModel,
                        name: data[i].contract.name
                    })
                }
                this.toggleList = new Array(this.compareDocumentsIds.length).fill(true);
                this.comparedList = new Array(this.compareDocumentsIds.length).fill(false);
            }
        });
    }

    async checkWalkThrough() {
        const tourType = this.currentUser?.productWalkThrough?.find(walkthrough => walkthrough.type === PRODUCT_WALKTHROUGH_TYPE.COMPARE);
        if (!tourType?.status) {
            this.initializeTour()
        }
    }


    async ngOnInit() {
        this.loading = true;
        if (this.comparisonExists) this.getComparison();
        else await this.getComparedDocuments();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["compareId"] && changes["compareId"].currentValue) {
            this.compareId = changes["compareId"].currentValue;
        }
    }
    ngAfterViewInit(): void {
        this.walkThroughSubscription = this.productWalkThroughService.walkThroughReset$.subscribe(type => {
            if (type == PRODUCT_WALKTHROUGH_TYPE.COMPARE) {
                this.initializeTour()
            }
        })
    }

    ngOnDestroy(): void {
        if (this.walkThroughSubscription)
            this.walkThroughSubscription.unsubscribe()
    }

    getComparison() {
        this.compareService.getComparison(this.compareId).subscribe((res) => {
            if (res) {
                this.document = res.data.contract;
                this.compareName = res.data.name;
                this.documentId = this.document._id;
                this.documentType = this.document.linkedDocType._id;
                this.compareDocuments = res.data.compareContracts.map((doc: any) => {
                    return doc.doc
                });
                this.compareDocumentsIds = res.data.compareContracts.map((doc: any) => {
                    return {
                        _id: doc._id,
                        docModel: doc.docModel,
                        name: doc.doc.name
                    }
                });
                this.compareResult = res.data.compare;
                this.kpiResult = res.data.kpiComparison;
                this.toggleList = new Array(this.compareDocumentsIds.length).fill(true);
                this.comparedList = new Array(this.compareDocumentsIds.length).fill(true);
                this.loading = false;
                this.checkWalkThrough()
            } else {
                this.compareResult = 'No comparison result found';
                this.loading = false;
                this.checkWalkThrough()
            }
        });
    }

    goBack() {
        window.close();
    }

    blurSpan(event: Event) {
        const target = event.target as HTMLElement;
        target.blur();
    }

    updateContractName(event: Event) {
        event.preventDefault()
        const target = event.target as HTMLElement;
        const newName = target.textContent?.trim() || 'Untitled';
        target.innerHTML = newName
        if (this.compareName && this.compareName !== newName) {
            this.compareName = newName;
            this.updateCompareName();
        }
    }

    updateCompareName() {
        this.compareService.updateComparisonName(this.compareId, this.compareName).subscribe((res) => {
            if (res && res.success) {
                this.editName = false;
                this.toast.success("Name updated successfully")
            }
        });
    }



    async getComparedDocuments(enable: boolean = false, reqNewComparison: boolean = true) {
        let reqCompareDocs = this.compareDocumentsIds.filter((doc, indx) => this.toggleList[indx]);
        this.contractsService.getCompareDocuments({ document: this.documentId, compareDocuments: reqCompareDocs, compareType: this.compareType, reqNewComparison: reqNewComparison }).subscribe(async (res) => {
            if (res && res.success) {
                this.document = res.data.result.document;
                this.compareDocuments = res.data.result.compareDocuments;
                this.compareId = reqNewComparison ? res.data.comparison._id : this.compareId;
                this.compareName = reqNewComparison ? res.data.comparison.name : this.compareName;
                this.enableCompare = enable ? enable && this.checkEnableCompare() : true;
                this.changeDocLoading = false;
                if (!enable) {
                    await this.getCompareResult();
                    await this.getCompareKpisResult();
                }
            } else {
                this.compareResult = 'No comparison result found';
                this.loading = false;
                this.checkWalkThrough()
            }
        });
    }
    async getCompareResult() {
        this.isSummaryLoading = true;
        const filteredList = this.compareDocumentsIds.filter((doc, indx) => this.toggleList[indx]);
        this.compareService.getCompareResult({ document: this.documentId, compareDocuments: filteredList, docModel: this.compareType, comparisonId: this.compareId }).subscribe((res) => {
            if (res) {
                this.compareDocumentsIds.forEach((doc, index) => this.comparedList[index] = true);
                if (res.status != '[DONE]') {
                    this.compareResult += res.content.message;
                    this.compareId = res.content.compareId;
                }
                else if (res.status == '[DONE]' && !this.compareResult) {
                    this.compareResult = res.content.message;
                    this.isSummaryLoading = false;
                    this.compareId = res.content.compareId;
                }
                else {
                    this.compareResult = res.content.message;
                    this.compareName = res.content.comparisonName;
                    this.compareId = res.content.compareId;
                    if (this.compareId && !this.comparisonExists)
                        this.updateUrl(this.compareId)
                    this.isSummaryLoading = false;
                }
                this.isSummaryLoading = false;
                this.loading = false;
                this.checkWalkThrough()
                this.enableCompare = false
            } else {
                this.compareResult = 'No Summary result found';
                this.loading = false;
                this.checkWalkThrough()
            }
        })
    }

    async getCompareKpisResult() {
        this.isKpiLoading = true;
        const filteredList = this.compareDocumentsIds.filter((doc, indx) => this.toggleList[indx]);
        this.compareService.getCompareKpiResult({ document: this.documentId, compareDocuments: filteredList, docModel: this.compareType, comparisonId: this.compareId }).subscribe((res) => {
            if (res) {
                this.graphCache = {};
                this.compareDocumentsIds.forEach((doc, index) => this.comparedList[index] = true);
                this.kpiResult = res.content.kpiResult;
                this.compareId = res.content.compareId;
                this.loading = false;
                this.checkWalkThrough()
                this.enableCompare = false;
                if (res.status == '[DONE]') {
                    this.isKpiLoading = false;
                    this.compareName = res.content.comparisonName;
                    this.compareId = res.content.compareId;
                    if (this.compareId && !this.comparisonExists)
                        this.updateUrl(this.compareId)
                    // this.enableCompare = this.checkEnableCompare();
                }
            } else {
                // this.kpiResult = 'No comparison result found';
                this.loading = false;
                this.checkWalkThrough()
            }
        })
    }

    waitForCondition(condition: () => boolean): Promise<void> {
        return new Promise((resolve) => {
            const observer = new MutationObserver(() => {
                if (condition()) {
                    resolve();
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });

            if (condition()) {
                resolve();
                observer.disconnect();
            }
        });
    }


    waitForElement(selector: string): Promise<Element> {
        return new Promise((resolve) => {
            const observer = new MutationObserver((mutations, observer) => {
                const el = document.querySelector(selector);
                if (el) {
                    resolve(el);
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });

            const el = document.querySelector(selector);
            if (el) {
                resolve(el);
                observer.disconnect();
            }
        });
    }


    initializeTour() {
        Promise.all([
            Promise.all([
                this.waitForElement('#step1'),
                this.waitForCondition(() => !this.isSummaryLoading)
            ]),
            Promise.all([
                this.waitForElement('#step2'),
                this.waitForCondition(() => this.kpiResult && this.kpiResult.length > 0 && !this.isKpiLoading)
            ]),
            Promise.all([
                this.waitForElement('#step3'),
                this.waitForCondition(() => this.kpiResult && this.kpiResult.length > 0 && !this.isKpiLoading)
            ]),
            this.waitForElement('#step4'),
            Promise.all([
                this.waitForElement('#step5'),
                this.waitForCondition(() => !this.changeDocLoading)
            ]),
            this.waitForElement('#step6'),
            this.waitForElement('#step7')
        ])
            .then(() => {
                this.driver = driver({
                    stageRadius: 14,
                    showProgress: true,
                    nextBtnText: 'Next',
                    allowClose: true,
                    prevBtnText: 'Previous',
                    onCloseClick: () => this.completeTour(),
                    onDestroyed: () => this.completeTour(),
                    popoverClass: 'custom-popover',

                    showButtons: ['next', 'previous'],
                    steps: [
                        ...(!(this.isSummaryLoading) ?
                            [{ element: '#step1', popover: { title: 'Comparison Summary', description: 'Leverage a structured and comprehensive overview of key sections across compared documents. The AI-driven summary highlights critical differences and similarities, ensuring efficient document analysis.', side: "bottom" as const, align: 'center' as const } }]
                            : []),

                        ...(!(this.kpiResult && this.kpiResult.length === 0 || this.isKpiLoading) ?
                            [{ element: '#step2', popover: { title: 'Key Performance Indicators', description: 'Stay ahead with data-driven insights! KPIs highlight critical metrics tailored to your document type, helping you assess key aspects at a glance.', side: "bottom" as const, align: 'start' as const } }]
                            : []),

                        ...(!(this.kpiResult && this.kpiResult.length === 0 || this.isKpiLoading) ?
                            [{ element: '#step3', popover: { title: 'Custom Key Performance Indicators', description: 'Shape your analysis the way you need! Define Custom KPIs to focus on the metrics that matter most to your project, ensuring your comparisons align with specific priorities and requirements.', side: "bottom" as const, align: 'start' as const } }]
                            : []),

                        { element: '#step4', popover: { title: 'Compare AI Chatbot', description: 'Engage in a dynamic conversation with InnDoc AI to explore insights across all compared documents. Ask questions, uncover key details, and gain a deeper understanding.', side: "left" as const, align: 'start' as const } },

                        ...(!this.changeDocLoading ?
                            [{ element: '#step5', popover: { title: 'Add more documents to compare', description: 'Expand your analysis by adding more documents to the comparison. Select from existing files, repository, and also previous versions to ensure a comprehensive review.', side: "left" as const, align: 'start' as const } }]
                            : []),

                        { element: '#step6', popover: { title: 'History', description: 'Need to revisit a previous comparison? Easily access and review past records for reference and tracking.', side: "bottom" as const, align: 'center' as const } },

                        { element: '#step7', popover: { title: 'Download', description: 'Get a comprehensive comparison report, outlining key insights for thorough analysis and informed decision-making.', side: "bottom" as const, align: 'start' as const } },
                    ]

                });
                this.driver.drive();
            })
            .catch(err => {
                console.error('Tour initialization failed:', err);
            });
    }


    completeTour() {
        let data = { type: PRODUCT_WALKTHROUGH_TYPE.COMPARE, status: true };
        this.userService.updateWalkThrough(data).subscribe(() => {
        });
    }

    openKpi(item: { _id: string, aspect: string, comparison: string, generateTable: boolean, generateGraph: boolean }) {
        this.toggleKpiDailog = true;
        this.selectedKpi = item;
    }

    openTable(item: { _id: string, aspect: string, comparison: string, generateTable: boolean, generateGraph: boolean }) {
        this.kpiCompareTableDialog.visible = true;
        this.kpiCompareTableDialog.loading = true;
        this.selectedKpi = item;
        // this.compareDocumentsIds = this.compareDocumentsIds.filter((doc, indx) => this.toggleList[indx]);
        // const compareDocIds = this.compareDocumentsIds.filter((doc, index) => this.comparedList[index]);
        let filteredComparedDocs = this.compareDocumentsIds.filter((doc, indx) => this.toggleList[indx]);
        this.compareService.getKpiCompareTable({ document: this.documentId, compareDocuments: filteredComparedDocs, kpi: item._id }).subscribe((res) => {
            if (res && res.success) {
                this.kpiCompareTableDialog.table = res.data.table;
                this.kpiCompareTableDialog.loading = false;
            } else {
                this.toast.error('Error fetching the comparison table.');
                this.kpiCompareTableDialog.loading = false;
            }
        })
    }

    openGraph(item: { _id: string, aspect: string, comparison: string, generateTable: boolean, generateGraph: boolean }) {
        this.kpiCompareGraphDialog.visible = true;
        this.kpiCompareGraphDialog.loading = true;
        this.selectedKpi = item;
        if (this.graphCache[item._id]) {
            this.kpiCompareGraphDialog.graph = this.graphCache[item._id].graph;
            this.kpiCompareGraphDialog.graphType = this.graphCache[item._id].graphType;
            this.kpiCompareGraphDialog.loading = false;
        } else {
            // this.compareDocumentsIds = this.compareDocumentsIds.filter((doc, indx) => this.toggleList[indx]);
            // const compareDocIds = this.compareDocumentsIds.filter((doc, index) => this.comparedList[index]);
            let filteredComparedDocs = this.compareDocumentsIds.filter((doc, indx) => this.toggleList[indx]);
            this.compareService.getKpiCompareGraph({ document: this.documentId, compareDocuments: filteredComparedDocs, kpi: item._id }).subscribe((res) => {
                if (res && res.success) {
                    const graphUrl = res.data.graph;
                    const graphType = res.data.graphType;
                    this.graphCache[item._id] = { graph: graphUrl, graphType: graphType };
                    this.kpiCompareGraphDialog.graph = graphUrl;
                    this.kpiCompareGraphDialog.graphType = graphType;
                    this.kpiCompareGraphDialog.loading = false;
                } else {
                    this.toast.error('Error fetching the comparison graph.');
                    this.kpiCompareTableDialog.loading = false;
                }
            })
        }
    }

    changeCompareDocument() {

        if (this.isSummaryLoading || this.isKpiLoading) {
            return this.toast.warn("Please wait for the current comparison to finish before adding more document's")
        }

        const _dialogRef = this.dialog.open(CompareSelectionComponent, {
            disableClose: true,
            width: '40vw',
            maxHeight: '80vh',
            data: {
                contractId: this.documentId,
                selectedDocuments: this.compareDocumentsIds,
                documentType: this.documentType
            }
        });
        _dialogRef.afterClosed().subscribe(doc => {
            if (doc?.length) {
                this.changeDocLoading = true;
                for (let document of doc) {
                    this.compareDocumentsIds.push({
                        _id: document.contract._id,
                        name: document.contract.name,
                        docModel: document.docModel
                    });
                    this.toggleList.push(true);
                }
                this.getComparedDocuments(true, false);

            }
        })
    }

    removeDocument(doc: (IContract | IFile & { summary: string }), index: number) {
        // if (this.compareDocuments.length === 1) {
        //     return this.toast.warn("At least one document must be selected for comparison")
        // }
        this.compareDocumentsIds = this.compareDocumentsIds.filter(document => document._id !== doc._id);
        this.toggleList.splice(index, 1);
        if (this.toggleList.every(toggle => toggle == false)) {
            this.enableCompare = false;
            return this.toast.warn("At least one document must be selected for comparison")
        }

        this.enableCompare = this.compareDocumentsIds.length > 0 && this.checkEnableCompare();
        // this.getComparedDocuments();
    }

    toggleChange(event: any, index: number) {

        if (this.toggleList.every((toggle, ind) => toggle == false || index == ind)) {
            this.enableCompare = false;
            this.toggleList[index] = true;
            return this.toast.warn("At least one document must be selected for comparison")
        }

        this.toggleList[index] = event.checked;
        if (this.toggleList.length === 1 && !event.checked) {
            this.enableCompare = false;
            return this.toast.warn("At least one document must be selected for comparison")
        }
        if (this.toggleList.every(toggle => toggle == false)) {
            this.enableCompare = false;
            return this.toast.warn("At least one document must be selected for comparison")
        }
        this.enableCompare = this.toggleList.some(toggle => !toggle) && this.checkEnableCompare();
    }


    checkForToggle(index: number) {
        if (this.toggleList.every((toggle, ind) => toggle == false || index == ind)) {
            return true;
        }
        return false;
    }

    downloadComparison() {
        this.downloadLoading = true
        this.compareService.downloadComparison({ document: this.documentId }).subscribe((res) => {
            if (res) {
                const blob: Blob = new Blob([new Uint8Array(res.data.data)], {
                    type: 'application/pdf'
                });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `Comparison.pdf`;
                document.body.append(link);
                link.click();
                link.remove();
                this.downloadLoading = false;
            }
        })
    }

    async compareDocs() {
        this.isSummaryLoading = true;
        this.isKpiLoading = true;
        this.enableCompare = false;
        this.getComparedDocuments(false, true);
    }

    checkEnableCompare() {
        return !this.isSummaryLoading && !this.isKpiLoading;
    }

    openAddKpiDialog(mode: 'Add' | 'Edit' = 'Add', index: number = 0) {
        this.kpiDialog.visible = true
        this.kpiDialog.mode = mode
    }


    onKpisDialogClose() {
        this.kpiName = ''
        this.kpiDescription = ''
    }

    saveKpi() {
        if (!this.kpiName?.trim()?.length || !this.kpiDescription?.trim()?.length) {
            return this.toast.warn("Please fill all the required fields")
        }
        this.isKpiLoading = true;
        let filteredComparedDocs = this.compareDocumentsIds.filter((doc, indx) => this.toggleList[indx]);
        this.kpiDialog.visible = false
        this.compareService.getCustomKpi({ document: this.documentId, compareDocuments: filteredComparedDocs, customKpis: [{ comparison_aspect: this.kpiName, description: this.kpiDescription }] }).subscribe((res) => {
            if (res) {
                let newCustomKpis = res.content.kpiResult;
                this.kpiResult = [...newCustomKpis, ...this.kpiResult,];
                this.loading = false;
                this.enableCompare = false;
                if (res.status == '[DONE]') {
                    this.isKpiLoading = false;
                    this.compareName = res.content.compareName;
                    this.compareId = res.content.compareId;
                    this.kpiName = ''
                    this.kpiDescription = ''
                }
            } else {
                this.isKpiLoading = false;
                this.loading = false;
            }
        }, error => {
            this.isKpiLoading = false;
            this.loading = false;
        })

    }

    enableEditing() {
        this.editName = true;
        this.cdr.detectChanges();
        setTimeout(() => {
            this.editableContent.nativeElement.focus();
        }, 0);
    }

    updateUrl(compareId: string) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { compareId: compareId },
            queryParamsHandling: 'merge',
            replaceUrl: true,
        });
    }

}