import { Injectable } from '@angular/core';
import { BaseService } from '../../@core/services/base.service';
import { environment } from '../../../environments/environment.dev';
import { HttpClient } from '@angular/common/http';
import { IUser } from '../../@core/models/model';
import { JOB_STATUS, JOB_TYPE } from '../../@core/models/enums';
import { JOB_FILTER_STATUS } from '../../@shared/components/notifications/notifications.component';
import { IFile } from '../components/settings/repository/repository.types';
import { IContract } from '../models/contract-management.model';

export interface IJob extends Document {
    readonly _id: string
    item: IFile | IContract,
    type: JOB_TYPE,
    status: JOB_STATUS,
    user: string | IUser,
    metadata: {
        contractId?: string
    },
    createdAt?: Date,
    updatedAt?: Date
}

@Injectable({ providedIn: 'root' })
export class JobService extends BaseService {

    apiUrl = environment.apiUrl + '/job';

    constructor(protected override http: HttpClient) { super(http); }

    getJobs(jobFilters: { selected: JOB_FILTER_STATUS, skip: number, limit: number }) {
        return this.post<IJob[]>(this.apiUrl, { jobFilters });
    }

    cancelJob(jobId: string) {
        return this.put(this.apiUrl, { jobId })
    }
}