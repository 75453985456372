import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../../../environments/environment.dev';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../@core/services/base.service';

export enum DOCUMENT_MODEL {
  CONTRACT = 'Contract',
  REPOSITORY = 'File',
  VERSION = 'Version'
}


@Injectable({
  providedIn: 'root'
})
export class ContractEditorService extends BaseService {

  private url = environment.apiUrl + '/editor-assistant'
  constructor(
    protected override http: HttpClient,
    protected ngZone: NgZone
  ) {
    super(http, ngZone);
  }

  getAISuggestions(data: { prefix?: string, prompt: string, suffix?: string, content?: string, documentId?: string }) {
    return this.get<string>(this.url + '/suggestions', data)
  }
}
