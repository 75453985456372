<section class="aspect-auto h-screen overflow-hidden">
    <section class="h-full flex flex-col">
        <app-top-bar style="z-index: 1000;"></app-top-bar>
        <div
            class="top-bar flex justify-between items-center p-4 rounded border-b border-t drop-shadow border-t-gray-200 border-b-gray-200 bg-white">
            <div class="flex flex-row items-center gap-2">
                <i class="pi pi-angle-left cursor-pointer m-2 text-primary" (click)="goBack()"></i>
                <div #editableContent scrollReset [attr.contenteditable]="editName"
                    [pTooltip]="editName ? undefined : compareName" tooltipPosition="top"
                    class="text-xl mx-3 max-w-[50rem] whitespace-nowrap overflow-x-auto inline-block no-scrollbar"
                    (keydown)="$event.key === 'Enter' ? blurSpan($event) : null" (blur)="updateContractName($event)">
                    {{compareName}}
                </div>
                <div>
                    <i class="pi pi-pen-to-square cursor-pointer small-icon" (click)="enableEditing()"></i>
                </div>
            </div>
            <div class="flex justify-end gap-4 items-center">
                <p-button (onClick)="compareDocs()" [disabled]="!enableCompare" icon="pi pi-sparkles"
                    styleClass="bg-arena-orange px-2 py-2  rounded-xl text-white font-semibold" type="submit"
                    label="Compare"></p-button>

                <p-button (click)="showHistory = true" [text]="true" icon="pi pi-history"
                    styleClass="bg-arena-orange px-2 py-2  rounded-xl text-white font-semibold" type="submit"
                    label="History" id="step6"></p-button>

                <p-button (click)="downloadComparison()" [loading]="downloadLoading" [disabled]="downloadLoading"
                    icon="pi pi-arrow-circle-down"
                    styleClass="bg-arena-orange px-2 py-2  rounded-xl text-white font-semibold" type="submit"
                    label="Download" id="step7"></p-button>
            </div>

        </div>
        <div class="h-full">
            @if(loading) {
            <div class="flex justify-center items-center  h-screen">
                <div class="loader"></div>
            </div>
            }@else {
            <p-splitter [style]="{height: 'calc(100vh - 140px)' }" [panelSizes]="panelSizes" styleClass="mb-5"
                [minSizes]="[60, 25]">
                <ng-template pTemplate>
                    <div class="flex flex-col w-full">
                        <div class="flex items-end">
                            @if(document || comparedDocument) {
                            <div class="flex flex-col p-4 overflow-x-auto w-full">
                                <div class="flex gap-4">
                                    <div class="flex-[5] flex overflow-auto gap-4 pt-2">
                                        <div class="rounded-lgbg-white">
                                            <ng-container
                                                *ngTemplateOutlet="documentTemplate; context: { document: document }"></ng-container>
                                        </div>
                                        @for (item of compareDocumentsIds; track $index) {
                                        <div class="rounded-lgbg-white">
                                            <ng-container
                                                *ngTemplateOutlet="compareTemplate; context: { document: item , index: $index}"></ng-container>
                                        </div>
                                        }
                                    </div>
                                    @if(compareDocumentsIds.length < COMPARISON_LIMIT) { <div
                                        class="mt-4 flex-1 w-full rounded-lg bg-orange-50 add-card">
                                        <ng-container *ngTemplateOutlet="addTemplate"></ng-container>
                                </div>
                                }
                            </div>
                        </div>
                        }
                    </div>
                    <hr>
                    <p-splitter [style]="{ width: '100%', height: 'calc(100vh - 200px)' }" [panelSizes]="[30,70]"
                        styleClass="mb-5" [minSizes]="[30, 30]">
                        <!-- Summary Section -->
                        <ng-template pTemplate>
                            <div class="h-full w-full flex flex-col p-4">
                                <div class="flex justify-between items-center mb-2" id="step1">
                                    <span class="font-bold text-xl">Comparison Summary</span>
                                    @if(!compareResult || isSummaryLoading) {
                                    <span
                                        class="flex gap-1 text-sm p-[0.2rem] bg-orange-200 text-orange-500 rounded-lg  border-orange-500 justify-around">
                                        <i class="pi pi-spin pi-spinner p-[0.2rem]"></i>
                                        <span class="mr-1">Generating</span>
                                    </span>
                                    }
                                    @if(!isSummaryLoading) {
                                    <i class="pi pi-arrow-up-right-and-arrow-down-left-from-center justify-end text-sm cursor-pointer p-2 border rounded-lg "
                                        (click)="toogleComparison = !toogleComparison"></i>
                                    }
                                </div>
                                @if(compareResult) {
                                <div class="flex-1 overflow-y-auto border rounded-xl p-4 mb-2">
                                    <markdown class="prose prose-compact text-justify" [data]="compareResult">
                                    </markdown>
                                </div>
                                }
                            </div>
                        </ng-template>

                        <!-- Compare Result Section -->
                        <ng-template pTemplate>
                            <div class="h-full w-full flex flex-col p-4 overflow-y-hidden">
                                <div class="flex justify-between items-center mb-2" id="step2">
                                    <span class="font-bold text-xl">Key Performance Indicators</span>
                                    @if(kpiResult && kpiResult.length==0 || isKpiLoading) {
                                    <span
                                        class="flex gap-1 text-sm p-[0.2rem] bg-orange-200 text-orange-500 rounded-lg  border-orange-500 justify-around">
                                        <i class="pi pi-spin pi-spinner p-[0.2rem]"></i>
                                        <span class="mr-1">Generating</span>
                                    </span>
                                    }
                                    @else {
                                    <p-button label="Add" severity="primary" icon="pi pi-plus"
                                        (onClick)="openAddKpiDialog()"
                                        styleClass="bg-arena-orange text-white p-1 px-3 rounded-xl" [outlined]="true"
                                        id="step3" /> <!--3-->
                                    }
                                </div>
                                <div class="flex-1 w-full p-4 overflow-y-auto">
                                    <div class="grid grid-cols-2 gap-4 pb-4">
                                        @for (item of kpiResult; track $index) {
                                        <div
                                            class="flex flex-col gap-2 justify-between rounded-xl border p-6 bg-white shadow-xs h-[12rem] overflow-y-hidden">
                                            <div class="flex items-center justify-between">
                                                <span class="font-bold">{{item.aspect}}</span>
                                                <div class="flex gap-2">
                                                    @if(item.generateTable) {
                                                    <i class="pi pi-table justify-end text-sm cursor-pointer p-2 border rounded-lg"
                                                        (click)="openTable(item)"></i>
                                                    }
                                                    @if(item.generateGraph) {
                                                    <i class="pi pi-chart-bar justify-end text-sm cursor-pointer p-2 border rounded-lg"
                                                        (click)="openGraph(item)"></i>
                                                    }
                                                    <i class="pi pi-arrow-up-right-and-arrow-down-left-from-center justify-end text-sm cursor-pointer p-2 border rounded-lg "
                                                        (click)="openKpi(item)"></i>
                                                </div>
                                            </div>
                                            <hr>
                                            <div class="text-justify"
                                                [innerHTML]="item.comparison | truncate:100 | formatText">
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-splitter>
        </div>
        </ng-template>
        <ng-template pTemplate>
            <div class="chat-container">
                <app-chat [contractId]="document._id" [showTags]="true" [showOptions]="true" [showHistory]="true"
                    [compareDocumentIds]="compareDocumentsIds" [compareId]="compareId" [chatSource]="ChatSource.COMPARE"
                    [suggestions]="suggestions" [docModel]="compareType" [kpis]="(kpiResult)" id="step4"></app-chat>
            </div>
        </ng-template>
        </p-splitter>
        }
        </div>
    </section>
</section>

<ng-template #documentTemplate let-document="document">
    <div class="drafted-card flex group w-[15rem] 2xl:w-[18rem]">
        <div class="w-full p-2">
            <div class="flex gap-2 items-end">
                <div class="flex justify-center align-center gap-3">
                    <img src="/icons/home-card-icon.svg" alt="icon" class="icon">
                </div>
                <h2 class="font-bold text-lg pt-2 truncate" [pTooltip]="document.name" tooltipPosition="top">
                    {{document.name |
                    truncate:20 }}</h2>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #compareTemplate let-document="document" let-index="index">
    <div class="drafted-card flex group w-[15rem] 2xl:w-[18rem]">
        <div class="w-full relative p-2">
            @if (compareDocumentsIds.length > 1) {
            @if(!checkForToggle(index)){
            <div class="absolute flex justify-end -top-6 -right-6 cursor-pointer group"
                (click)="removeDocument(document , index)">
                <span class="text-md rounded-full px-1.5 bg-red-100">
                    <i class="pi pi-times text-xs text-red-500"></i>
                </span>
            </div>
            }

            }

            <div class="flex justify-between gap-2 items-end">
                <div class="flex justify-center align-center gap-3">
                    <img src="/icons/home-card-icon.svg" alt="icon" class="icon">
                </div>
                <h2 class="font-bold text-lg pt-2 truncate mr-auto" [pTooltip]="document.name" tooltipPosition="top">
                    {{document.name | truncate: 20 }}
                </h2>
                @if (compareDocumentsIds.length > 1) {
                <div>
                    @if(checkForToggle(index)){
                    <mat-slide-toggle class="ml-2" color="orange-palette" disabled="true" [checked]="toggleList[index]"
                        (change)="toggleChange($event,index)"></mat-slide-toggle>
                    }
                    @else {
                    <mat-slide-toggle class="ml-2" color="orange-palette" [checked]="toggleList[index]"
                        (change)="toggleChange($event,index)"></mat-slide-toggle>
                    }
                </div>
                }
            </div>

        </div>
    </div>
</ng-template>

<ng-template #addTemplate let-document="document">
    @if(changeDocLoading){
    <div class="flex gap-2 items-center" (click)="changeCompareDocument()">
        <span>
            <i class="rounded-full pi pi-spin pi-spinner text-xl 2xl:text-2xl text-orange-400"></i>
        </span>
        <div class="text-container">
            <span class="text-center text-sm  text-gray-500">Adding document to compare</span>
        </div>
    </div>
    }
    @else {
    <div class="flex gap-2 items-center" 
        (click)="changeCompareDocument()" id="step5">
        <span>
            <i class="rounded-full pi pi-plus-circle text-xl 2xl:text-2xl text-orange-400"></i>
        </span>
        <div class="text-container">
            <span class="text-center text-sm  text-gray-500">Add more documents to compare</span>
        </div>
    </div>
    }

</ng-template>

@if(selectedKpi){
<p-dialog [header]="selectedKpi.aspect" [modal]="true" [(visible)]="toggleKpiDailog" [style]="{ width: '50rem' }">
    <hr class="mb-3">
    <markdown class="prose prose-compact text-justify" [data]="selectedKpi.comparison"></markdown>
</p-dialog>

<p-dialog header="Comparison Table" [modal]="true" [(visible)]="kpiCompareTableDialog.visible"
    [style]="{ width: '50rem' }">
    <span class="font-bold">{{selectedKpi.aspect}}</span>
    <hr>
    @if(kpiCompareTableDialog.loading) {
    <p-progressSpinner styleClass="my-4" [style]="{width: '20px', height: '20px'}"></p-progressSpinner>
    }
    @else {
    <div class="prose prose-compact text-justify text-xs prose-ul:pl-1 prose-ol:pl-2"
        [innerHTML]="kpiCompareTableDialog.table | formatText:false" dynamicComponentRenderer>
    </div>
    }
</p-dialog>

<p-dialog header="Comparison Graph" [modal]="true" [(visible)]="kpiCompareGraphDialog.visible"
    [style]="{ width: '50rem' }">
    <span class="font-bold">{{selectedKpi.aspect}}</span>
    <hr>
    @if(kpiCompareGraphDialog.loading) {
    <p-progressSpinner styleClass="my-4" [style]="{width: '20px', height: '20px'}"></p-progressSpinner>
    }
    @else {
    <div class="flex justify-center">
        <img [src]="kpiCompareGraphDialog.graph">
    </div>
    }
</p-dialog>
}

<p-dialog header="Comparison Result" [modal]="true" [(visible)]="toogleComparison" [style]="{ width: '50rem' }">
    <markdown class="prose prose-compact text-justify" [data]="compareResult">
    </markdown>
</p-dialog>


<p-sidebar [(visible)]="showHistory" position="right">
    <ng-template pTemplate="headless">
        <div class="flex flex-col">
            <div class="flex items-center justify-between p-4 flex-shrink-0 border-b">
                <span class="text-lg font-semibold">Compare History</span>
                <div class="flex gap-2 items-center">
                    <p-button type="button" (onClick)="showHistory=false" icon="pi pi-times" [text]="true"
                        severity="secondary"></p-button>
                </div>
            </div>
            <app-compare-history [documentId]="documentId"></app-compare-history>
        </div>
    </ng-template>

</p-sidebar>


<p-dialog [(visible)]="kpiDialog.visible" [header]="'Header'" [style]="{width: '40vw'}" [modal]="true"
    [draggable]="false" (onDialogClose)="onKpisDialogClose()">
    <ng-template pTemplate="header">
        <div class="font-bold">{{kpiDialog.mode}} Custom Comparison</div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="flex flex-col align-items-center gap-1 mb-3">
            <label for="name" class="w-6rem">
                Name <span class="text-red-500">*</span>
            </label>
            <input pInputText id="name" class="flex-auto border rounded-md input" autocomplete="off"
                [(ngModel)]="kpiName" required />
        </div>
        <div class="flex flex-col align-items-center gap-1">
            <label for="description" class="w-6rem">
                Description <span class="text-red-500">*</span>
            </label>
            <textarea pInputText id="description" class="flex-auto border rounded-md input" autocomplete="off"
                [(ngModel)]="kpiDescription"></textarea>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="mb-3">
            <p-button label="Save" severity="primary" icon="pi pi-save" (onClick)="saveKpi()" class="custom-icon-size"
                styleClass="w-100 bg-arena-orange p-1 px-3 rounded-xl text-white" [outlined]="true" />
        </div>
    </ng-template>
</p-dialog>