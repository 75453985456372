import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { TopBarComponent } from './components/top-bar/top-bar.component';

@Component({
  selector: 'app-app-layout',
  standalone: true,
  imports: [RouterModule, RouterModule, ButtonModule, TopBarComponent],
  templateUrl: './app-layout.component.html',
  styleUrl: './app-layout.component.scss'
})
export class AppLayoutComponent {

  constructor() { }

}
