import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { marked } from 'marked';
import { DYNAMIC_COMPONENT } from '../directives/dynamic-component-renderer.directive';

@Pipe({
  standalone: true,
  name: 'formatText',
})
export class FormatTextPipe implements PipeTransform {
  showOpenButton: boolean = true;
  constructor(private sanitizer: DomSanitizer) {
    marked.setOptions({
      gfm: true,
      breaks: true,
      renderer: this.createCustomRenderer()
    });
  }

  transform(value: string, showOpenButton: boolean = true): SafeHtml {
    if (!value) return '';
    this.showOpenButton = showOpenButton;
    const html = marked(value);
    return this.sanitizer.bypassSecurityTrustHtml(html as string);
  }

  private createCustomRenderer() {
    const renderer = new marked.Renderer();

    // Customize link rendering for security
    renderer.link = (href: string, title: string, text: string) => {
      const sanitizedHref = this.sanitizer.sanitize(SecurityContext.URL, href) || '';
      return `<a href="${sanitizedHref}" ${title ? `title="${title}"` : ''} target="_blank" rel="noopener noreferrer">${text}</a>`;
    };

    renderer.table = (header: string, body: string) => {
      return `
              <div class="overflow-x-auto text-xs dynamic-component" data-component="${DYNAMIC_COMPONENT.TABLE_MODEL}" data-show-open-button="${this.showOpenButton}">
                <div class="flex justify-end dynamic-table-model" >
                </div>
                <table class="min-w-full table-auto border-collapse border border-gray-200 mb-4">
                  <thead class="bg-gray-100">
                    ${header.replace(/<th>/g, '<th class="px-4 py-2 border border-gray-300 text-left text-xs font-medium text-gray-700">')
          .replace(/<\/th>/g, '</th>')}
                  </thead>
                  <tbody class="bg-white text-xs">
                    ${body.replace(/<td>/g, '<td class="px-4 py-2 border border-gray-300 text-xs text-gray-600">')
          .replace(/<\/td>/g, '</td>')
          .replace(/<tr>/g, '<tr class="hover:bg-gray-50">')
          .replace(/<\/tr>/g, '</tr>')}
                  </tbody>
                </table>
              </div>
            `;
    };

    renderer.code = (code: string, language: string) => {
      try {
        if (language === "json") {
          let parsed_json = JSON.parse(code);
          let stringified_code = JSON.stringify(parsed_json);
          return `<span class="dynamic-component" data-component="${DYNAMIC_COMPONENT.GRAPH_MODEL}"  data-json-content='${this.escapeHtml(stringified_code)}'></span>`;
        }
        return ``
      }
      catch (error) {
        return ``;
      }
    }

    // Add dynamicComponentRenderer directive to parent for sources.
    renderer.text = (text: string) => {
      let formattedText = text;

      formattedText = formattedText.replace(/\n/g, '<br>');

      formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

      formattedText = formattedText.replace(/\*(.*?)\*/g, '<em>$1</em>');

      // Jira ticket ID
      formattedText = formattedText.replace(
        /\b(ARN-\d+)\b/g,
        '<a href="https://inncircles.atlassian.net/browse/$1" target="_blank">$1</a>'
      );

      formattedText = formattedText.replace(
        /\[\s*([^\]]+?)\s*\]\(\s*(https?:\/\/[^\s]+?)\s*\)/g,
        (match, p1, p2) => `<a href="${p2.trim()}" target="_blank">${p1.trim()}</a>`
      );

      return formattedText.replace(
        /&lt;(\d+)&gt;(?:\[(.+?)\]:)?(.*?)&lt;\/\1&gt;\s*\[Page\s+([\d\s-]+)\]/g,
        (match, group, label, content, pageNum) => {
          const escapedContent = this.escapeHtml(content);
          const sourceNum = group.trim();
          const tooltipContent = JSON.stringify({
            label,
            sourceText: escapedContent,
            pageNum
          }).replace(/"/g, '&quot;')
          return ` 
          <span class="relative group">
            <span class="dynamic-component"
            data-component="${DYNAMIC_COMPONENT.TOOLTIP}"
            data-tooltip-content="${tooltipContent}"
            data-tooltip-number="${sourceNum}"
            ></span>
          </span>
          `;
        }
      );
    };
    return renderer;
  }

  escapeHtml(unsafe: string): string {
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
  }
}