import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'duration',
    standalone: true
})
export class DurationPipe implements PipeTransform {
    transform(createdAt: Date | string, updatedAt: Date | string): string {
        if (!createdAt || !updatedAt) return '';

        const created = new Date(createdAt).getTime();
        const updated = new Date(updatedAt).getTime();
        const durationMs = updated - created;

        if (durationMs < 0) return '';

        const timeUnits: { value: number; label: string }[] = [
            { value: Math.floor(durationMs / (1000 * 60 * 60 * 24)), label: 'd' },
            { value: Math.floor((durationMs / (1000 * 60 * 60)) % 24), label: 'h' },
            { value: Math.floor((durationMs / (1000 * 60)) % 60), label: 'm' },
            { value: Math.floor((durationMs / 1000) % 60), label: 's' }
        ];

        return timeUnits
            .filter(unit => unit.value > 0)
            .map(unit => `${unit.value}${unit.label}`)
            .join(' ') || '0s';
    }
}
