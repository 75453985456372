import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment.dev";
import { BaseService } from "../../@core/services/base.service";
import { ILinkDocument } from "../../@core/models/model";
import { CONTRACT_TYPE, IContract } from "../models/contract-management.model";
import { Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class LinkDocumentService extends BaseService {

    public reloadLinkedFiles = new Subject()

    constructor(
        protected override http: HttpClient
    ) {
        super(http);
    }

    apiUrl = environment.apiUrl + '/link-document';

    fetchLinkDocument(documentId: string, filters?: { skip: number, limit: number }) {
        return this.get<{
            data: ILinkDocument[],
            total: number
        }>(this.apiUrl + '/' + documentId, filters);
    }

    linkDocument(sourceDocument: string, targetDocuments: string[], linkedBy?: string) {
        return this.put<ILinkDocument[]>(this.apiUrl, { sourceDocument, targetDocuments, linkedBy });
    }
    deleteLinkDocument(documentId: string) {
        return this.delete<ILinkDocument>(this.apiUrl + '/' + documentId);
    }
}