@if(showOpenTable) {
<div class="flex justify-end">
    <i class="pi pi-arrow-up-right-and-arrow-down-left-from-center absolute ml-2 text-xs cursor-pointer p-1 border rounded-lg bg-white shadow-md"
        (click)="openModel()"></i>
</div>
}


<p-dialog header="" [modal]="true" [(visible)]="OpenTableDialog" [style]="{ width: '80%' }">
    <div class="prose prose-compact text-justify text-xs prose-ul:pl-1 prose-ol:pl-2 overflow-x-auto"
        [innerHTML]="table">
    </div>
</p-dialog>