import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContractChecklistService, IGetContractChecklist } from '../../services/contract-checklist-item.service';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { CONTRACT_CHECKLIST_STATUS } from '../../../@core/models/enums';
import { toFixedNumber } from '../../../common/utils';
import { NGX_ECHARTS_CONFIG, NgxEchartsModule } from 'ngx-echarts';
import { GAUGE_COLORS, setGuageChart } from '../../utils';
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { ToastService } from '../../../@core/services/toast.service';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ChecklistsService } from '../../services/checklists.service';
import { FormsModule } from '@angular/forms';
import { IChecklistItem } from '../../models/settings.model';
import _ from 'lodash';
import { RiskMatrixComponent } from "../risk-matrix/risk-matrix.component";
import { ContractInsightsService } from '../../services/contract-insights.service';
import { SOCKET_EVENTS, SocketService } from '../../../@core/services/socket.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ContractService } from '../../services/contracts.service';

const IMPACT_COLOR = {
  1: '#1EB768',
  2: '#B2D34A',
  3: '#F7A232',
  4: '#E86829',
  5: '#DC3622'
}

@Component({
  selector: 'app-check-points',
  standalone: true,
  imports: [CommonModule, ButtonModule, NgxEchartsModule, TooltipModule, AccordionModule, CardModule, DialogModule, RadioButtonModule, FormsModule, RiskMatrixComponent, ProgressSpinnerModule],
  providers: [
    {
      provide: NGX_ECHARTS_CONFIG,
      useValue: {
        echarts: () => import('echarts')
      }
    }
  ],
  templateUrl: './check-points.component.html',
  styleUrl: './check-points.component.scss'
})
export class CheckPointsComponent implements OnInit, OnDestroy {

  @Input() contractId: string = "";
  checklists: IGetContractChecklist[] = []
  contractChecklistStatus = CONTRACT_CHECKLIST_STATUS
  statusCount: Record<CONTRACT_CHECKLIST_STATUS, number> = {
    [CONTRACT_CHECKLIST_STATUS.COMPLIANCE]: 0,
    [CONTRACT_CHECKLIST_STATUS.NOT_IN_COMPLIANCE]: 0,
    [CONTRACT_CHECKLIST_STATUS.SAVE_FOR_LATER]: 0
  }
  IMPACT_COLOR: { [key: number]: string } = IMPACT_COLOR;
  complianceRate: number = 0
  chartOption: echarts.EChartsOption = {}
  loading: boolean = false
  aiLoading: boolean = true
  selectedChecklist: IChecklistItem['_id'] = '';
  sourceDialog: {
    selectedChecklist: any,
    currentIdx: number
    visible: boolean,
    selectedSource: string,
    selectedRecommended: string
    selectedSourceDocument: string
  } = { visible: false, selectedSource: '', selectedRecommended: '', selectedSourceDocument: '', selectedChecklist: '', currentIdx: 0 };

  rulesDialog: {
    visible: boolean,
    checklistId: string | null,
    rules: { rule: string, impact: number }[],
    selectedRule: { rule: string, impact: number },
  } = { visible: false, checklistId: null, rules: [], selectedRule: { rule: '', impact: 0 } };

  riskMatrixDialog: {
    visible: boolean,
    selectedImpact: number,
    selectedProbability: number
  } = { visible: false, selectedImpact: 0, selectedProbability: 0 };
  extractChecklistsSubscription: any
  riskScore: number = 0
  riskChartOption: echarts.EChartsOption = {}
  checklistItemsInSync: boolean = false
  constructor(private contractChecklistService: ContractChecklistService, private toastService: ToastService, private checklistService: ChecklistsService, private contractInsightsService: ContractInsightsService, private socketService: SocketService, private contractService: ContractService) {
    this.extractChecklistsSubscription = this.socketService.on<{ checklists: IGetContractChecklist[], lastElement?: boolean, singleElement: boolean }>(SOCKET_EVENTS.CHECKLIST_ITEMS_EXTRACTED).subscribe(result => {
      this.updateChecklistsData(result[0].checklists, result[0].lastElement, result[0].singleElement);
    });
  }

  ngOnInit(): void {
    this.complianceRate = 0
    this.chartOption = setGuageChart(this.complianceRate, [GAUGE_COLORS.RED, GAUGE_COLORS.YELLOW, GAUGE_COLORS.GREEN])
    this.riskChartOption = setGuageChart(this.riskScore, [GAUGE_COLORS.GREEN, GAUGE_COLORS.YELLOW, GAUGE_COLORS.RED])
    this.getContractChecklistMappings();
  }

  ngOnDestroy() {
    if (this.extractChecklistsSubscription) {
      this.extractChecklistsSubscription.unsubscribe();
    }
  }

  extractChecklists(refresh: boolean = false, checklistId?: IGetContractChecklist['_id']) {
    if (refresh) {
      this.checklists = []
      this.chartOption = setGuageChart(0, [GAUGE_COLORS.RED, GAUGE_COLORS.YELLOW, GAUGE_COLORS.GREEN])
      this.riskChartOption = setGuageChart(0, [GAUGE_COLORS.GREEN, GAUGE_COLORS.YELLOW, GAUGE_COLORS.RED])
    }
    if (checklistId) {
      let checklistItem = this.checklists.find(item => item._id == checklistId)
      if (checklistItem) {
        checklistItem.loading = true //item will be replaced with updated data, so no need to make it false
        checklistItem.status = undefined
        this.updateComplianceRate(this.checklists)
      }
    }
    else
      this.loading = true
    this.contractChecklistService.extractChecklistItemsFromContract(this.contractId, refresh, checklistId).subscribe({
      next: res => {
        if (res.success) {
          this.loading = false
          const checklistsInSync = res.data
          if (!checklistId) {//needless to call for every item refresh.
            this.aiLoading = !checklistsInSync
            this.getContractChecklistMappings();
          }
        }
      },
      error: err => {
        this.loading = false
        this.toastService.error('Error while extracting checklists.')
      }
    })
  }

  getContractChecklistMappings() {
    this.loading = true
    this.contractChecklistService.getContractChecklistMappings(this.contractId).subscribe(
      {
        next: res => {
          if (res.success && res.data) {
            this.checklists = res.data.checklists
            this.aiLoading = res.data.inProgressJob
            this.updateInSync(this.checklists)
            this.updateComplianceRate(this.checklists)
            this.getRiskScore()
          }
          this.loading = false
        },
        error: err => {
          this.loading = false
        }
      })
  }

  getRiskScore() {
    this.contractService.getContractRiskScore(this.contractId).subscribe(res => {
      if (res.success) {
        this.riskScore = res.data
        if (this.checklists && this.checklists.length)
          this.riskChartOption = setGuageChart(this.riskScore, [GAUGE_COLORS.GREEN, GAUGE_COLORS.YELLOW, GAUGE_COLORS.RED])
      }
    })
  }

  updateInSync(checklists: IGetContractChecklist[] = []) {
    this.checklistItemsInSync = checklists.every(item => item.checklistItemInSync ?? false)
  }

  updateChecklistsData(checklists: IGetContractChecklist[], lastElement?: boolean, singleElement?: boolean) {
    const updatedChecklistsMap = new Map(checklists.map(item => [item._id, item]));
    this.checklists = this.checklists.map(item => updatedChecklistsMap.get(item._id) ?? item).filter(item => item !== undefined)
    this.updateInSync(this.checklists)
    this.updateComplianceRate(this.checklists)
    if (lastElement) {
      if (!singleElement) this.aiLoading = false;// do not update for single element update.
      this.getRiskScore()
    }
  }

  updateComplianceRate(checklists: IGetContractChecklist[] = []) {
    let totalMappingsCount = 0
    this.statusCount = {
      [CONTRACT_CHECKLIST_STATUS.COMPLIANCE]: 0,
      [CONTRACT_CHECKLIST_STATUS.NOT_IN_COMPLIANCE]: 0,
      [CONTRACT_CHECKLIST_STATUS.SAVE_FOR_LATER]: 0
    }
    for (const checklist of checklists) {
      if (checklist.status) {
        this.statusCount[checklist.status]++;
        totalMappingsCount++;
      }
    }
    this.complianceRate = totalMappingsCount ? toFixedNumber((this.statusCount[CONTRACT_CHECKLIST_STATUS.COMPLIANCE] / totalMappingsCount) * 100, 2) : 0
    this.chartOption = setGuageChart(this.complianceRate, [GAUGE_COLORS.RED, GAUGE_COLORS.YELLOW, GAUGE_COLORS.GREEN])
  }

  getChecklistRules() {
    this.checklistService.getRulesLinkedToChecklist(this.selectedChecklist).subscribe((res) => {
      if (res && res.success) {
        this.rulesDialog.rules = res.data.impactRules;
        const currentRule = this.rulesDialog.rules.find(rule =>
          rule.rule === this.rulesDialog.selectedRule?.rule &&
          rule.impact === this.rulesDialog.selectedRule?.impact
        );
        if (currentRule) {
          this.rulesDialog.selectedRule = currentRule;
        }
        this.riskMatrixDialog.selectedImpact = this.riskMatrixDialog.selectedImpact ? this.riskMatrixDialog.selectedImpact : res.data.impact;
        this.riskMatrixDialog.selectedProbability = res.data.probability;
      } else {
        this.toastService.error('Error fetching the rules');
      }
    });
  }

  changeRule() {
    const data = { contract: this.contractId, checklistItem: this.selectedChecklist, impactRule: this.rulesDialog.selectedRule };
    this.contractChecklistService.updateContractChecklistMapping(data).subscribe(res => {
      if (res && res.success) {
        this.toastService.success('Successfully updated the impact rule for the selected checklist.');
        const checklistItem = this.checklists.find(item => item._id == this.selectedChecklist);
        if (checklistItem) checklistItem.impactRule = data.impactRule;
      } else {
        this.toastService.error('Error updating the impact rule for the selected checklist.');
      }
      this.closeRulesDialog();
    })
  }

  closeRulesDialog = _.debounce(() => {
    this.rulesDialog.visible = false;
    // this.getContractChecklistMappings();
  }, 500)

  mappingChanged(checklist: IGetContractChecklist, status: CONTRACT_CHECKLIST_STATUS) {
    this.contractChecklistService.saveContractChecklistMapping({ contract: this.contractId, checklistItem: checklist._id, status }).subscribe(res => {
      if (res.success) {
        // this.getContractChecklistMappings()
        const checklistItem = this.checklists.find(item => item._id == checklist._id);
        if (checklistItem) checklistItem.status = status;
      }
    })
  }

  openChangeRuleDialog(data: { checklist: IChecklistItem['_id'], impactRule?: { rule: string, impact: number } }) {
    this.selectedChecklist = data.checklist;
    this.rulesDialog.visible = true;
    this.rulesDialog.selectedRule = data.impactRule || { rule: '', impact: 0 };
    this.getChecklistRules();
  }

  openSourceDialog(checklist: { selectedChecklist: {}, currentIdx: number, source: string, sourceDocument: string, recommended?: string }) {
    this.sourceDialog.selectedChecklist = checklist.selectedChecklist;
    this.sourceDialog.currentIdx = checklist.currentIdx;
    this.sourceDialog.selectedSource = checklist.source;
    this.sourceDialog.selectedRecommended = checklist.recommended || '';
    this.sourceDialog.selectedSourceDocument = checklist.sourceDocument || '';
    this.sourceDialog.visible = true;
  }

  openRiskMatrixDialog(data: { checklist: IChecklistItem['_id'], impactRule?: { rule: string, impact: number } }) {
    this.selectedChecklist = data.checklist;
    this.riskMatrixDialog.visible = true;
    this.riskMatrixDialog.selectedImpact = data.impactRule?.impact || 0;
    this.getChecklistRules();
  }

  copyToClipboard() {
    const text = this.sourceDialog.selectedRecommended;
    navigator.clipboard.writeText(text);
    this.toastService.success('Copied to clipboard');
  }
}