<div class="flex flex-col gap-2 m-3 p-3">
    <!-- header -->
    <div class="flex justify-between items-center">
        <span class="font-semibold text-lg">Compare Document</span>
        <i class="pi pi-times cursor-pointer" (click)="closeDialog()"></i>
    </div>
    <!-- body -->
    <div class="flex flex-col gap-2">
        <div>
            <p-inputGroup class="search-bar">
                <p-inputGroupAddon>
                    <i class="pi pi-search text-arena-orange"></i>
                </p-inputGroupAddon>
                <input pInputText type="text" class="w-full" (input)="onSearch()" placeholder="Search document"
                    [(ngModel)]="searchValue" [style]="{outline: 'none'}" />
            </p-inputGroup>
        </div>
        <p-tabView (onChange)="tabChange($event)">
            <p-tabPanel header="Documents">
                <div class="h-[40vh] overflow-y-auto">
                    <p-dataView [value]="contracts" [rows]="rows" [sortField]="sortField" [sortOrder]="sortOrder"
                        [lazy]="true" [loading]="loading" [emptyMessage]="'No Documents Found'">
                        <ng-template pTemplate="list" let-contracts>
                            <div class="flex flex-col gap-2">
                                @for (contract of contracts; track contract._id) {
                                <p-card class="cursor-pointer">
                                    <div class="flex flex-row gap-4">
                                        <div class="flex items-center">
                                            <p-checkbox [ngModel]="selectedDocuments.has(contract._id)" [binary]="true"
                                                [disabled]="!selectedDocuments.has(contract._id) && disableCheckBox"
                                                (onChange)="selectContract(contract,compareType.CONTRACT)"></p-checkbox>
                                        </div>
                                        <div class="w-full">
                                            <span class="font-semibold">{{contract.name}}</span>
                                            <span class="flex justify-between mt-1">
                                                <span class="flex flex-row gap-2 items-center">
                                                    <img [src]="userProfilePicture[contract.createdBy?._id]"
                                                        alt="User Avatar" class="w-6 h-6 rounded-full" />
                                                    <span class="text-sm">{{contract.createdBy | fullName}} </span>
                                                </span>
                                                <span class="text-sm">{{contract.createdAt | dateFormatter}}</span>
                                            </span>
                                        </div>
                                    </div>
                                </p-card>
                                }
                            </div>
                        </ng-template>
                        <ng-template pTemplate="paginator">
                            <span></span>
                        </ng-template>
                    </p-dataView>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Repository">
                <div class="h-[40vh] overflow-y-auto">
                    <p-dataView [value]="files" [rows]="rows" [sortField]="sortField" [sortOrder]="sortOrder"
                        [lazy]="true" [loading]="loading" [emptyMessage]="'No Repositories Found'">
                        <ng-template pTemplate="list" let-contracts>
                            <div class="flex flex-col gap-2">
                                @for (contract of contracts; track contract._id) {
                                <p-card class="flex flex-col gap-2 cursor-pointer">
                                    <div class="flex flex-row gap-4">
                                        <div class="flex items-center">
                                            <p-checkbox [ngModel]="selectedDocuments.has(contract._id)" [binary]="true"
                                                [disabled]="!selectedDocuments.has(contract._id) && disableCheckBox"
                                                (onChange)="selectContract(contract,compareType.REPOSITORY)"></p-checkbox>
                                        </div>
                                        <div class="w-full">
                                            <span class="font-semibold">{{contract.name}}</span>
                                            <span class="flex justify-between mt-1">
                                                <span class="flex flex-row w-full gap-2 items-center">
                                                    <img [src]="userProfilePicture[contract.createdBy?._id]"
                                                        alt="User Avatar" class="w-6 h-6 rounded-full" />
                                                    <span class="text-sm">{{contract.createdBy | fullName}} </span>
                                                </span>
                                                <span class="text-sm">{{contract.createdAt | dateFormatter}}</span>
                                            </span>
                                        </div>
                                    </div>
                                </p-card>
                                }
                            </div>
                        </ng-template>
                        <ng-template pTemplate="paginator">
                            <span></span>
                        </ng-template>
                    </p-dataView>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Versions">
                <div class="h-[40vh] overflow-y-auto">
                    <p-dataView [value]="versions" [rows]="rows" [sortField]="sortField" [sortOrder]="sortOrder"
                        [lazy]="true" [loading]="loading" [emptyMessage]="'No Versions Found'">
                        <ng-template pTemplate="list" let-contracts>
                            <div class="flex flex-col gap-2">
                                @for (contract of contracts; track contract._id) {
                                <p-card class="flex flex-col gap-2 cursor-pointer">
                                    <div class="flex flex-row gap-4">
                                        <div class="flex items-center">
                                            <p-checkbox [ngModel]="selectedDocuments.has(contract._id)" [binary]="true"
                                                [disabled]="!selectedDocuments.has(contract._id) && disableCheckBox"
                                                (onChange)="selectContract(contract,compareType.VERSION)"></p-checkbox>
                                        </div>
                                        <div class="w-full">
                                            <span class="font-semibold">{{contract.name}}</span>
                                            <span class="flex justify-between mt-1">
                                                <span class="flex flex-row gap-2 items-center">
                                                    <img [src]="userProfilePicture[contract.createdBy?._id]"
                                                        alt="User Avatar" class="w-6 h-6 rounded-full" />
                                                    <span class="text-sm">{{contract.createdBy | fullName}} </span>
                                                </span>
                                                <span class="text-sm">{{contract.createdAt | dateFormatter}}</span>
                                            </span>
                                        </div>
                                    </div>
                                </p-card>
                                }
                            </div>
                        </ng-template>
                        <ng-template pTemplate="paginator">
                            <span></span>
                        </ng-template>
                    </p-dataView>
                </div>
            </p-tabPanel>
            <!-- <p-tabPanel header="Upload files">
                <div class="h-[40vh] pt-4 overflow-y-auto">
                    @if(!uploadedDocs.length){
                    <app-file-drop [fileTypes]="allowedFileTypes" (uploadData)="onFileUploaded($event)"
                        [maxFileSize]="maxFileSize" />
                    }@else {
                    <p-dataView [value]="uploadedDocs" [rows]="rows" [sortField]="sortField" [sortOrder]="sortOrder"
                        [lazy]="true" [loading]="loading" [emptyMessage]="'No Versions Found'">
                        <ng-template pTemplate="list" let-contracts>
                            <div class="flex flex-col gap-2">
                                @for (contract of contracts; track contract) {
                                <p-card class="flex flex-col gap-2 cursor-pointer">
                                    <div class="flex flex-row gap-4">
                                        <div class="flex items-center">
                                            <p-checkbox [ngModel]="selectedDocuments.has(contract.name)" [binary]="true"
                                                [disabled]="!selectedDocuments.has(contract.name) && disableCheckBox"
                                                (onChange)="selectContract(contract,compareType.UPLOADED_DOCUMENT)"></p-checkbox>
                                        </div>
                                        <div class="w-full">
                                            <span class="font-semibold">{{contract.name}}</span>
                                        </div>
                                    </div>
                                </p-card>
                                }
                            </div>
                        </ng-template>
                        <ng-template pTemplate="paginator">
                            <span></span>
                        </ng-template>
                    </p-dataView>
                    }
                </div>
            </p-tabPanel> -->
        </p-tabView>
        <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"
            class="" />
        <div class="w-full flex justify-between items-center">
            <div>
                {{selectedDocuments.size}}/{{maxFilesCanBeSelected}} selected
            </div>
            <div>
                <p-button severity="primary" styleClass="w-100 px-3 bg-arena-orange py-2 rounded-xl text-sm text-white"
                    label="Compare" (onClick)="submitCompare()" class="w-full mt-2" type="button"></p-button>
            </div>
        </div>

    </div>
</div>