import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { AuthenticationService } from '../../@core/services/authentication.service';
import { ToastService } from '../../@core/services/toast.service';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [FormsModule, CommonModule, ButtonModule, ReactiveFormsModule, InputTextModule, RouterModule],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {

  resetForm!: FormGroup;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.resetForm = new FormGroup({
      email: new FormControl('', Validators.required)
    });
  }

  get f() {
    return this.resetForm.controls;
  }

  onSubmit(): void {
    if (!this.resetForm.valid) {
      this.resetForm.markAllAsTouched();
      return;
    }

    const { email } = this.resetForm.value;
    this.authService.sendVerificationEmail(email).subscribe({
      next: (res) => {
        if(res.success) this.toastService.success('Reset Password sent successfully', 'Success');
        this.resetForm.reset()
      },
      error: (err) => {
        this.toastService.error('Reset Password failed', 'Error');
      }
    });

  }


}
