import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IShareUser, IUser } from '../../../@core/models/model';
import { TimelineModule } from 'primeng/timeline';
import { BadgeComponent } from "../badge/badge.component";
import { UserAvatarPipe } from '../../pipes/user-avatar-pipe';
import { CardModule } from 'primeng/card';
import { CONTRACT_APPROVAL_STATUS } from '../user-chips/user-chips.component';
import { DateFormatterPipe } from '../../pipes/dateFormater.pipe';
import { ButtonModule } from 'primeng/button';
import { EmailAvatarPipe } from '../../pipes/email-avatar.pipe';
import { CONTRACT_STATUS } from '../badge/badge.enums';
import { ToastService } from '../../../@core/services/toast.service';
import { ApprovalConfService } from '../../../contract-management/services/approval-conf.service';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { USER_TYPE } from '../../../@core/models/enums';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { CheckScreenSize } from '../../../services/check-screen-size.service';


export enum FLOW_TYPE {
  APPROVAL = 'APPROVAL',
  SIGNATORY = 'SIGNATORY'
}
@Component({
  selector: 'app-approval-workflow',
  standalone: true,
  imports: [TimelineModule, BadgeComponent, UserAvatarPipe, EmailAvatarPipe, CardModule, DateFormatterPipe, ButtonModule, CommonModule, TooltipModule, TruncatePipe],
  templateUrl: './approval-workflow.component.html',
  styleUrl: './approval-workflow.component.scss'
})

export class ApprovalWorkflowComponent {
  @Input() approvers !: IShareUser[]
  @Input() canEdit: boolean = false
  @Output() deleteApproverEvent = new EventEmitter<string>()
  @Output() deleteSignatoryEvent = new EventEmitter<string>()
  @Output() downloadSignatoryEvent = new EventEmitter<{ email: string, name: string, status: string, signedAttachment?: string }>()
  @Input() signatories !: { email: string, name: string, status: string, signedAttachment?: string }[]
  @Input() flowType !: FLOW_TYPE
  @Input() contractStatus !: CONTRACT_STATUS

  CONTRACT_STATUS = CONTRACT_STATUS
  FLOW_TYPE = FLOW_TYPE
  APPROVAL_STATUS !: CONTRACT_APPROVAL_STATUS
  USER_TYPE = USER_TYPE
  isSmallScreen: boolean = false

  constructor(
    private approvalService: ApprovalConfService,
    private toast: ToastService,
    private checkScreenSizeService: CheckScreenSize
  ) {
  }

  ngOnInit() {
    this.isSmallScreen = this.checkScreenSizeService.checkScreenSize()
  }

  deleteSignatory(email: string) {
    this.deleteSignatoryEvent.emit(email)
  }

  async downloadSignedDocument(signatory: { email: string, name: string, status: string, signedAttachment?: string }) {
    this.downloadSignatoryEvent.emit(signatory)
  }

  isUserUnverified(item: any): boolean {
    return item && item.user && typeof item.user !== 'string' && item.user.verified === false;
  }

}

